import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { postHorario } from '../../network/lib/admin'
import { listaHoras } from '../../data'

function ModalAgregarHorario({horarios, close}) {

    const [data, setData] = useState({
        nombre: '',
        anio: 0,
        catedra: {
            nombre: '',
            docente: ''
        },
        dia: {
            nombre: '',
            horaInicio: "",
            horaFin: "",
            inicio: 0,
            fin: 0
        },
        diaDos: {
            nombre: '',
            horaInicio: "",
            horaFin: "",
            inicio: 0,
            fin: 0
        },
        turno: ''
    })

    const agregarHorario = (data) => {
        postHorario(data).then((res)=>{
            console.log(res)
            close()
        }).catch((err)=>{
            console.log(err)
        })
    }

    useEffect(() => {
        console.log(data)
    }, )
    

    return (
        <Container>
            <Modal>
                <Alerta>
                    <Titulo>Agregar horario</Titulo>
                </Alerta>
                <Row>
                    <Input placeholder="Asignatura" type="text" onChange={(e)=> setData({...data, nombre: e.target.value})}/>                    
                </Row>
                <Row>
                    <SmallInput placeholder="Cátedra" type="text" onChange={(e)=> setData({...data, catedra: {...data.catedra, nombre: e.target.value}})}/>
                    <SmallInput placeholder="Docente" type="text" onChange={(e)=> setData({...data, catedra: {...data.catedra, docente: e.target.value}})}/>
                </Row>
                <Row>
                    <SmallSelect onChange={(e)=> setData({...data, anio: e.target.value})}>
                        <option value="Año">Año</option>
                        <option value="1">Primero</option>
                        <option value="2">Segundo</option>
                        <option value="3">Tercero</option>
                        <option value="4">Cuarto</option>
                        <option value="5">Quinto</option>
                        <option value="6">Sexto</option>
                    </SmallSelect>
                    <SmallSelect onChange={(e)=> setData({...data, turno: e.target.value})}>
                        <option value="Turno">Turno</option>
                        <option value="Mañana">Mañana</option>
                        <option value="Tarde">Tarde</option>
                        <option value="Noche">Noche</option>
                    </SmallSelect>
                </Row>
                <Row>
                    <Titulo>Día 1</Titulo>
                </Row>
                <Row>
                    <SelectDia onChange={(e)=> setData({...data, dia: {...data.dia, nombre: e.target.value}})}>
                        <option value="">Dia</option>
                        <option value="Lunes">Lunes</option>
                        <option value="Martes">Martes</option>
                        <option value="Miércoles">Miércoles</option>
                        <option value="Jueves">Jueves</option>
                        <option value="Viernes">Viernes</option>
                    </SelectDia>
                    <SelectHora onChange={(e)=> setData({...data, dia: {...data.dia, horaInicio: e.target.value}})}>
                        <option value="">Inicio</option>
                        {listaHoras.map(option=> {
                            return(<option value={option.hora}>{option.hora}</option>)
                        })}
                    </SelectHora>
                    <SelectHora onChange={(e)=> setData({...data, dia: {...data.dia, horaFin: e.target.value}})}>
                        <option value="">Fin</option>
                        {listaHoras.map(option=> {
                            return(<option value={option.hora}>{option.hora}</option>)
                        })}
                    </SelectHora>
                </Row>
                <Row>
                    <Titulo>Día 2</Titulo>
                </Row>
                <Row>
                    <SelectDia onChange={(e)=> setData({...data, diaDos: {...data.diaDos, nombre: e.target.value}})}>
                        <option value="">Dia</option>
                        <option value="Lunes">Lunes</option>
                        <option value="Martes">Martes</option>
                        <option value="Miércoles">Miércoles</option>
                        <option value="Jueves">Jueves</option>
                        <option value="Viernes">Viernes</option>
                    </SelectDia>
                    <SelectHora onChange={(e)=> setData({...data, diaDos: {...data.diaDos, horaInicio: e.target.value}})}>
                        <option value="">Inicio</option>
                        {horarios.map(option=> {
                            return(<option value={option.hora}>{option}</option>)
                        })}
                    </SelectHora>
                    <SelectHora onChange={(e)=> setData({...data, diaDos: {...data.diaDos, horaFin: e.target.value}})}>
                        <option value="">Fin</option>
                        {horarios.map(option=> {
                            return(<option value={option.hora}>{option}</option>)
                        })}
                    </SelectHora>
                </Row>
                <Botones>
                    <Cerrar onClick={close}>Cancelar</Cerrar>
                    <Confirmar onClick={() => agregarHorario(data)}>Agregar</Confirmar>
                </Botones>
            </Modal>
        </Container>
    )
}

const Container = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 200px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
`

const Input = styled.input`
    outline: none;
    border: 1px solid #F8F9FA;
    color: #989898;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 420px;
    &::placeholder{
        font-weight: 600;
        color: #989898;
    }
`

const SmallInput = styled(Input)`
    width: 180px;
`

const SmallSelect = styled.select`
    outline: none;
    border: 1px solid #F8F9FA;
    color: #989898;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 222px;
    font-weight: 600;
`

const Modal = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    max-width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 768px){
        margin: 10px;
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
`

const Botones = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 30px;
    @media(max-width: 768px){
        margin-top: 20px;
    }
`

const Cerrar = styled.button`
    cursor: pointer;
    border: none;
    padding: 15px 38px;
    margin-left: auto;
    width: 30%;
    background-color: #C4C4C4;
    border-radius: 14px;
    font-weight: 600;
    color: #fff;
    @media(max-width: 768px){
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 13px;
    }
`

const Confirmar = styled.button`
    cursor: pointer;
    border: none;
    padding: 15px 38px;
    margin-right: auto;
    width: 30%;
    background-color: #7778fe;
    border-radius: 14px;
    font-weight: 600;
    color: #fff;
    @media(max-width: 768px){
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 13px;
    }
`

const Alerta = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`

const Titulo = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #525252;
    margin-bottom: 30px;
    @media(max-width: 768px){
        font-size: 18px;
    }
`

const Row = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    ${Titulo}{
        font-size: 18px;
        margin-bottom: 10px;
    }
`

const SelectDia = styled(SmallSelect)`
    width: 184px;
`

const SelectHora = styled(SmallSelect)`
    width: 120px;
`

export default ModalAgregarHorario