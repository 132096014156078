import React, {useState, useEffect} from 'react';
import { GlobalStyleLogin, Ingresar, Titulo, ButtonIngresar } from './Login.Styled';
import { useHistory } from 'react-router-dom';
import { checkLoginAdmin, registrarAdmin } from '../../network/lib/admin';

function Registrar() {

    let history = useHistory();

    const [data, setData] = useState({
        username: '',
        password: ''
    })

    const [status, setStatus] = useState("")

    const registrar =  () => {
        registrarAdmin(data).then((res)=>{
            res.status===200 ? setStatus("Usuario creado con exito.") : setStatus("Ha ocurrido un error.")
        })
    }

    useEffect(() => {
        checkLoginAdmin().then((res)=>{
            if (res.data.loggedIn === false || res.data.role !== 'admin') {
                history.push('/admin')
            };
        })
    }, []);

    return (
        <div>
            <GlobalStyleLogin />
            <Ingresar>
                <Titulo>Registrar</Titulo>
                <label>Usuario</label>
                <input type="text" onChange={(e) => setData({...data, username: e.target.value})} value={data.username}></input>
                <label>Contraseña</label>
                <input type="text" onChange={(e) => setData({...data, password: e.target.value})} value={data.password}></input>
                <ButtonIngresar onClick={registrar}>Registrar</ButtonIngresar>
                {status}
            </Ingresar>
        </div>
    )
}

export default Registrar