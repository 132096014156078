import React from 'react'
import styled from 'styled-components'

function BuscarEstudiante({change}) {
  return (
    <Input type="text" placeholder="Buscar estudiante" onChange={(e) => {change(e.target.value)}}></Input>
  )
}

const Input = styled.input`
    margin-top: auto;
    font-size: 16px;
    padding: 16px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #F8F9FA;
    font-weight: 600;
`

export default BuscarEstudiante