import React from 'react'
import { GlobalStyleListado } from './Listado.styled'

function Listado() {
  return (
    <div>
        <GlobalStyleListado />
    </div>
  )
}

export default Listado