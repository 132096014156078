import React, {useEffect, useState} from 'react'
import { Contenedor, GlobalStyle, Navbar, Logout } from './Licencia.Styled'
import Logo from './../../assets/fapyd.svg';
import { useHistory } from 'react-router-dom';
import Atribucion from './Pasos/Atribucion'
import Acuerdo from './Pasos/Acuerdo'
import Autor from './Pasos/Autor'
import Documento from './Pasos/Documento'
import Publicacion from './Pasos/Publicacion'
import { checkLoginLicencia, enviarFormularioLicencia, enviarPFCLicencia, logoutLicencia } from '../../network/lib/licencia';
import axios from 'axios';

function Inicio() {
    let history = useHistory();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [ext, setExt] = useState("");
    let form = new FormData();
    const [data, setData] = useState({
        licencia: "By",
        apellido: "",
        nombre: "",
        tipoDoc: "DNI",
        numDoc: "",
        telefono: "",
        mail: "",
        titulo: "",
        tutor: "",
        coTutor: "",
        coAutor: "",
        abstract: "",
        claves: { uno: "", dos: "", tres: "", cuatro: "", cinco: "", seis: "", siete: ""},
        defensa: "",
        aprobacion: "Inmediata",
        pfc: "",
        link: "",
        size: ""
    });

    const nextStep = () => {
        setStep(step + 1);
    }

    const prevStep = () => {
        setStep(step - 1);
    }

    const logout =  () => {
        logoutLicencia().then(()=>{
            history.push('/licencia');
        })
    }

    const enviar = () => {
        const config = {     
            headers: { 'Content-Type': 'multipart/form-data' }
        }
        let formTwo = new FormData();
        setExt(ext.shift())
        formTwo.append('file', data.pfc, data.numDoc + `.${ext}`)
        setLoading(true)
        axios.all([enviarFormularioLicencia(data), enviarPFCLicencia(formTwo, config)])
        .then(axios.spread((...res)=>{
            if(res[1].status===200){
                history.push('/licencia/enviado')
            }
        })).catch((err)=>{
            setLoading(false)
            // setStatus(err.response.data.message)
            history.push('/licencia/enviado')
        });
    }

    useEffect(() => {
        checkLoginLicencia().then((response)=>{
          if (response.data.loggedIn === false) {
            history.push('/licencia')
          };
        });
      }, []);

    function evaluateStep(step) {
        switch(step) {
            case 1:
                return (
                    <Acuerdo nextStep={nextStep} />
                );
            case 2:
                return (
                    <Atribucion setData={setData} data={data} nextStep={nextStep} prevStep={prevStep}/>
                );
            case 3:
                return(
                    <Autor setData={setData} data={data} nextStep={nextStep} prevStep={prevStep}/>
                );
            case 4:
                return(
                    <Documento setData={setData} data={data} nextStep={nextStep} prevStep={prevStep}/>
                );
            case 5:
                return(
                    <Publicacion setData={setData} form={form} data={data} nextStep={enviar} prevStep={prevStep} status={status} loading={loading} setExt={setExt}/>
                );
        }
    }

    return (
        <div>
            <GlobalStyle />
            <Navbar>
                <img src={Logo} alt=""></img>
                <Logout onClick={logout}>Cerrar sesión</Logout>
            </Navbar>
            {evaluateStep(step)}
        </div>
    )
}

export default Inicio
