import React, {useEffect} from 'react'
import { Contenedor, } from './Licencia.Styled'
import { Container, GlobalStyle, Titulo, Continuar } from './Enviado.styled';
import { useHistory } from 'react-router-dom';
import { checkLoginLicencia } from '../../network/lib/licencia';

function Enviado() {

    let history = useHistory()

    // useEffect(() => {
    //     checkLoginLicencia().then((response)=>{
    //       if (response.data.loggedIn === false) {
    //         history.push('/licencia')
    //       };
    //     });
    //   }, []);

    return(
        <Contenedor>
            <GlobalStyle />
            <Container>
              <Titulo>La licencia de depósito y el PFC han sido enviados con éxito.</Titulo>
              <Continuar href="https://fapyd.unr.edu.ar/arquitectura/solicitud-del-titulo-de-grado/" target="_blank">
                Continuá el trámite de egreso
              </Continuar>
            </Container>
        </Contenedor>
    )
}

export default Enviado;
