import React, {useEffect, useState} from 'react'
import { Contenedor, Texto, Siguiente, Anterior, Dato, Select, Input, Paso, Botones, Alerta } from '../Licencia.Styled';

function Autor({setData, data, nextStep, prevStep}) {

    const [status, setStatus] = useState('');
    const [validEmail, setValidMail] = useState(false);

    const verif = () => {
        if (data.apellido === '' || data.nombre === '' || data.numDoc === '' || data.telefono === '' || data.mail === '') {
            setStatus('Completar todos los campos.')
        } else {
            if(!validEmail){
                setStatus('Ingrese un mail válido.')
            }else{
                nextStep()
            }
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    }, [])

    const selected = (val) => {
        if (data.tipoDoc === val) {
            return true;
        } else {
            return false;
        }
    }

    const checkValidMail = (e) =>{
        // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(e) ) {
            setValidMail(true);
        }
    }

    return (
        <div>
            <Contenedor>
            <Paso> Paso 2 · Datos del autor </Paso>
                <Texto>
                    <p>El nombre del AUTOR quedará claramente identificado en el Repositorio Hipermedial UNR como responsable del/os objeto/s digital/es depositado/s, y no se realizará ninguna alteración, salvo las permitidas por este acuerdo.</p>
                    <Dato>Apellido/s:</Dato>
                    <Input type="text" onChange={(e) => setData({...data, apellido: e.target.value})} value={data.apellido} required/>
                    <Dato>Nombre/s:</Dato>
                    <Input type="text" onChange={(e) => setData({...data, nombre: e.target.value})} value={data.nombre} required/>
                    <Dato>Tipo de documento:</Dato>
                    <Select name="tipodocumento" onChange={(e) => setData({...data, tipoDoc: e.target.value})}>
                        <option value="DNI" defaultValue={selected("DNI")}>DNI</option>
                        <option value="CI" defaultValue={selected("CI")}>CI</option>
                        <option value="PAS" defaultValue={selected("PAS")}>PAS</option>
                    </Select>
                    <Dato>Número de documento:</Dato>
                    <Input type="text" onChange={(e) => setData({...data, numDoc: e.target.value})} value={data.numDoc} required/>
                    <Dato>Teléfono:</Dato>
                    <Input type="text" minLength="6" onChange={(e) => setData({...data, telefono: e.target.value})} value={data.telefono} required/>
                    <Dato>Correo electrónico:</Dato>
                    <Input type="email" onChange={(e) => {setData({...data, mail: e.target.value}); checkValidMail(e.target.value)}} value={data.mail} required/>
                    <Alerta>{status}</Alerta>
                </Texto>
                <Botones>
                    <Anterior onClick={prevStep}>Anterior</Anterior>
                    <Siguiente onClick={verif}>Siguiente</Siguiente>
                </Botones>
            </Contenedor>
        </div>
    )
}

export default Autor
