import { axiosClient, axiosCaptcha } from "../api";

export const guardarCuadro = (data) => {
    return(axiosClient.post('/cuadro/generar', data))
}

export const verificarCaptcha = (token) => {
    return(axiosCaptcha.post('/cuadro/verificar', token))
}

export const getMaterias = () => {
    return(axiosClient.get('/admin/horarios'))
}