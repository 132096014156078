import React, { useState } from 'react'
import styled from 'styled-components'
import ModalBorrarHorario from './Modal.BorrarHorario'
import ModalEditarHorario from './Modal.EditarHorario'

function BloqueHorario({data}) {

    const [openEditar, setOpenEditar] = useState(false)
    const [openBorrar, setOpenBorrar] = useState(false)

    const close = () => {
      setOpenEditar(false)
      setOpenBorrar(false)
    }

    var horarios = [];

    function setHorarios(horarios) {
        for (var i = 7; i < 24; i++) {
            horarios.push(`${i}:00`);
            horarios.push(`${i}:30`);
        }
        horarios.push('0:00')
        return true
    }

  return (
      <>
      {openEditar ? setHorarios(horarios) ? <ModalEditarHorario horarios={horarios} data={data} close={close}/>: null : null}
      {openBorrar ? <ModalBorrarHorario data={data} close={close}/>: null}
        <Container>
            <Campo>
                {data.info.anio}
            </Campo>
            <Campo>
                {data.info.nombre}
            </Campo>
            <Campo>
                {data.info.catedra.nombre}
            </Campo>
            <Campo>
                {data.info.catedra.docente}
            </Campo>
            <Campo>
                {data.info.dia.nombre}
            </Campo>
            <Campo>
                {data.info.dia.horaInicio} - {data.info.dia.horaFin}
            </Campo>
            <Campo>
                {data.info.diaDos.nombre}
            </Campo>
            <Campo>
                {data.info.diaDos.horaInicio} - {data.info.diaDos.horaFin}
            </Campo>
            <Campo>
                {data.info.turno}
            </Campo>
            <Campo>
                <Editar onClick={() => {setOpenEditar(true)}}>Editar</Editar>
                <Borrar onClick={() => {setOpenBorrar(true)}}>Borrar</Borrar>
            </Campo>
        </Container>
      </>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #F8F9FA;
`

const Campo = styled.div`
    width: 100px;
    &:nth-child(1){
        width: 40px;
    }
    padding: 6px;
    padding-left: 16px;
    font-weight: 700;
    color: #000;
    display: flex;
`

const Editar = styled.button`
    cursor: pointer;
    background-color: #7778fe;
    color: #fff;
    font-weight: 600;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
`

const Borrar = styled.button`
    margin-left: 10px;
    cursor: pointer;
    background-color: #FF0000;
    color: #fff;
    font-weight: 600;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
`


export default BloqueHorario