import React from 'react'
import { listaHoras } from '../../../data'

export function checkHora(data){

    var value

    switch (data) {
        case "7:00":
            value = 4
            break;
        case "7:30":
            value = 5
            break;
        case "8:00":
            value = 6
            break;
        case "8:30":
            value = 7
            break;
        case "9:00":
            value = 8
            break;
        case "9:30":
            value = 9
            break;
        case "10:00":
            value = 10
            break;
        case "10:30":
            value = 11
            break;
        case "11:00":
            value = 12
            break;
        case "11:30":
            value = 13
            break;
        case "12:00":
            value = 14
            break;
        case "12:30":
            value = 15
            break;
        case "13:00":
            value = 16
            break;
        case "13:30":
            value = 17
            break;
        case "14:00":
            value = 18
            break;
        case "14:30":
            value = 19
            break;
        case "15:00":
            value = 20
            break;
        case "15:30":
            value = 21
            break;
        case "16:00":
            value = 22
            break;
        case "16:30":
            value = 23
            break;
        case "17:00":
            value = 24
            break;
        case "17:30":
            value = 25
            break;
        case "18:00":
            value = 26
            break;
        case "18:30":
            value = 27
            break;
        case "19:00":
            value = 28
            break;
        case "19:30":
            value = 29
            break;
        case "20:00":
            value = 30
            break;
        case "20:30":
            value = 31
            break;
        case "21:00":
            value = 32
            break;
        case "21:30":
            value = 33
            break;
        case "22:00":
            value = 34
            break;
        case "22:30":
            value = 35
            break;
        case "23:00":
            value = 36
            break;
        case "23:30":
            value = 37
            break;
        case "0:00":
            value = 38
            break;
        default:
            break;
    }

  return value
}