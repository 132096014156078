import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import '../../assets/styles.css';
import background from '../../assets/back.svg';

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
`

export const Navbar = styled.div`
  background-color: #0001fe;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1.5em;
  font-family: 'Montserrat', 'sans-serif';
  img {
    height: 40px;
  }
`

export const Container = styled.div`
    background-color: #ffffff;
    display: flex;
    margin-left: auto;
    @media (max-width: 1100px){
        flex-direction: column-reverse;
    }
`;

export const ListaHorarios = styled.div`
    display: flex;
    // align-items: center;
    flex-direction: column;
    // margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    width: max-content;
    padding: 1em;
    border-radius: 20px;
    background-color: #F3F2F7;
    @media (max-width: 1400px){
        width: 400px;
    }
    @media (max-width: 450px){
        padding: .5em;
        width: 350px;
    }
    @media (max-width: 390px){
        padding: .5em;
        width: 280px;
    }
`;



export const Inside = styled.div`
    border: 0px solid #6667FE;
    grid-column: 1/2;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #6667FE;
    &:nth-child(2n){
        border-top: none;
        border-bottom: none;
    }

`

export const Fila = styled.div`
    grid-column: 2/7;
    display: flex;
    flex-direction: row;
    grid-row: 1/2;
    div{
        width: 150px;
        text-align: center;
        background-color: #7778fe;
        text-transform: uppercase;
        font-size: 14px;
        color: #fff;
        font-weight: 600;
        line-height: 40px;
        border-bottom: 4px solid #6667fe;
    }
`

export const Bloque = styled.div`
    background-color: white;
    border-radius: 18px;
    padding: .5em 1em;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 400px;
    @media (max-width: 1100px){
        height: 200px;
    }
    @media (max-width: 450px){
        padding: 4px 8px;
    }

`

export const Dashboard = styled.div`
    display: block;
    margin-right: auto;
    margin-left: 2em;
    margin-top: 3em;
    @media (max-width: 1400px){
        margin-left: 1em;
    }
    @media (max-width: 1300px){
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
    @media(max-width: 450px){
        padding-left: 10px;
    }
`

export const Turnos = styled.div`
    @media (max-width: 1400px){
        width: 400px;
        display: flex;
        flex-wrap: wrap;
    }
    @media (max-width: 450px){
        width: 350px;
    }
    @media (max-width: 350px){
        width: 240px;
    }

`

export const Option = styled.div`
    font-weight: 700;
    font-size: 14px;
    padding: 6px 20px;
    background-color: ${props => props.check ? "#C1DD84" : "white"};
    color: #525252;
    border: 2px solid #C1DD84;
    border-radius: 20px;
    margin: 7px 12px 5px;
    cursor: pointer;
    :nth-child(1){
        margin-left: 0;
    }
    :nth-child(6){
        margin-left: 0;
        margin-top: 0;
    }
    input{
        display: none;
    }
    @media (max-width: 450px){
        font-size: 10px;
        padding: 5px 12px;
    }
`

export const Anio = styled.div`
    font-weight: 700;
    font-size: 14px;
    padding: 6px 20px;
    background-color: ${props => props.check ? "#7778fe" : "white"};
    color: ${props => props.check ? "#F3F2F7" : "#525252"};
    border: 2px solid #7778fe;
    border-radius: 20px;
    margin: 7px 12px 5px;
    cursor: pointer;
    :nth-child(1){
        margin-left: 0;
    }
    :nth-child(6){
        margin-left: 0;
        margin-top: 0;
    }
    input{
        display: none;
    }
    @media (max-width: 450px){
        font-size: 10px;
        padding: 5px 12px;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 550px;
    margin-bottom: 10px;
`

export const Label = styled.div`
    display: block;
    font-weight: 700;
    font-size: 14px;
    @media (max-width: 450px){
        font-size: 11px;
    }
`

export const Inicial = styled.div`
    display: block;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-top: 180px;
    color: #989898;
    @media (max-width: 1100px){
        margin-top: 90px;
    }
    @media (max-width: 450px){
        font-size: 10px;
    }
`

export const Borrar = styled.button`
    padding: 10px 24px;
    width: fit-content;
    background-color: #FF0000;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    margin-right: 10px;
    @media (max-width: 768px){
        padding: 8px 20px;
        font-size: 11px;
    }
    img{
        margin-right: 10px;
    }
`

export const CaptchaContainer = styled.div`
    margin-top: 10px;
    transform: scale(0.9);
    margin-left: -10px;
`

export const Exportar = styled.button`
    padding: 10px 24px;
    width: fit-content;
    background-color: #525252;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    @media (max-width: 768px){
        padding: 8px 20px;
        font-size: 11px;
    }
    img{
        margin-right: 10px;
    }
`

export const Alert = styled.div`
    display: flex;
    margin-top: 20px;
    img{
        width: 20px;
        height: 20px;
        margin: auto 0;
    }
    h5{
        margin: 0;
        margin-left: 6px;
        font-weight: 600;
    }
`

export const Ingresar = styled.div`
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    background-color: #FDFDFD;
    border: 2px solid #C4C4C4;
    border-radius: 20px;
    padding: 30px 60px 50px;
    label {
        margin: 1em 0 10px;
        margin-left: 0;
        color: #909090;
        font-weight: 600;
    }
    input {
        width: 340px;
        padding: 20px 10px;
        border: 1px solid #c4c4c4;
        background-color: #F5F5F5;
    }
`

export const Titulo = styled.div`
    font-weight: 800;
    color: #6667FE;
    font-size: 28px;
    line-height: 50px;
    border-bottom: 4px solid #6667FE;
    margin-bottom: 1em;
`
export const ButtonIngresar = styled.button`
    width: 150px;
    padding: 5px 30px;
    background: transparent;
    border-radius: 10px;
    text-transform: uppercase;
    border: 2px solid #6667fe;
    font-weight: bold;
    color: #6667fe;
    margin-left: auto;
    margin-top: 30px;
    font-size: 16px;
    cursor: pointer;
`

export const GlobalStyleLogin = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background: url(${background}) no-repeat;
    }
`