import React, { useState } from 'react'
import styled from 'styled-components'
import { updateHorario } from '../../network/lib/admin'

function ModalEditarHorario({data, horarios, close}) {

    const [newData, setNewData] = useState({
        id: data.id,
        nombre: data.info.nombre,
        anio: data.info.anio,
        catedra: {
            nombre: data.info.catedra.nombre,
            docente: data.info.catedra.docente,
        },
        dia: {
            nombre: data.info.dia.nombre,
            horaInicio: data.info.dia.horaInicio,
            horaFin: data.info.dia.horaFin,
        },
        diaDos: {
            nombre: data.info.diaDos.nombre,
            horaInicio: data.info.diaDos.horaInicio,
            horaFin: data.info.diaDos.horaFin,
        },
        turno: data.info.turno,
    })

    const selected = (param, val) => {
        if (param === val) {
            return true;
        } else {
            return false;
        }
    }

    const editarHorario = (newdata) => {
        updateHorario(newdata).then((res)=>{
            console.log(res)
            close()
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <Container>
            <Modal>
                <Alerta>
                    <Titulo>Editar horario</Titulo>
                </Alerta>
                <Row>
                    <Input value={newData.nombre} placeholder="Asignatura" type="text" onChange={(e)=> setNewData({...newData, nombre: e.target.value})}/>                    
                </Row>
                <Row>
                    <SmallInput value={newData.catedra.nombre} placeholder="Cátedra" type="text" onChange={(e)=> setNewData({...newData, catedra: {...newData.catedra, nombre: e.target.value}})}/>
                    <SmallInput value={newData.catedra.docente} placeholder="Docente" type="text" onChange={(e)=> setNewData({...newData, catedra: {...newData.catedra, docente: e.target.value}})}/>
                </Row>
                <Row>
                    <SmallSelect onChange={(e)=> setNewData({...newData, anio: e.target.value})}>
                        <option value="Año">Año</option>
                        <option value="1" selected={selected(data.info.anio, 1)}>Primero</option>
                        <option value="2" selected={selected(data.info.anio, 2)}>Segundo</option>
                        <option value="3" selected={selected(data.info.anio, 3)}>Tercero</option>
                        <option value="4" selected={selected(data.info.anio, 4)}>Cuarto</option>
                        <option value="5" selected={selected(data.info.anio, 5)}>Quinto</option>
                        <option value="6" selected={selected(data.info.anio, 6)}>Sexto</option>
                    </SmallSelect>
                    <SmallSelect onChange={(e)=> setNewData({...newData, turno: e.target.value})}>
                        <option value="Turno">Turno</option>
                        <option value="Mañana" selected={selected(data.info.turno, "Mañana")}>Mañana</option>
                        <option value="Tarde" selected={selected(data.info.turno, "Tarde")}>Tarde</option>
                        <option value="Noche" selected={selected(data.info.turno, "Noche")}>Noche</option>
                    </SmallSelect>
                </Row>
                <Row>
                    <Titulo>Día 1</Titulo>
                </Row>
                <Row>
                    <SelectDia onChange={(e)=> setNewData({...newData, dia: {...newData.dia, nombre: e.target.value}})}>
                        <option value="">Dia</option>
                        <option value="Lunes" selected={selected(data.info.dia.nombre, "Lunes")}>Lunes</option>
                        <option value="Martes" selected={selected(data.info.dia.nombre, "Martes")}>Martes</option>
                        <option value="Miércoles" selected={selected(data.info.dia.nombre, "Miércoles")}>Miércoles</option>
                        <option value="Jueves" selected={selected(data.info.dia.nombre, "Jueves")}>Jueves</option>
                        <option value="Viernes" selected={selected(data.info.dia.nombre, "Viernes")}>Viernes</option>
                    </SelectDia>
                    <SelectHora onChange={(e)=> setNewData({...newData, dia: {...newData.dia, horaInicio: e.target.value}})}>
                        <option value="">Inicio</option>
                        {horarios.map(option=> {
                            return(<option value={option} selected={selected(data.info.dia.horaInicio, option)}>{option}</option>)
                        })}
                    </SelectHora>
                    <SelectHora onChange={(e)=> setNewData({...newData, dia: {...newData.dia, horaFin: e.target.value}})}>
                        <option value="">Fin</option>
                        {horarios.map(option=> {
                            return(<option value={option} selected={selected(data.info.dia.horaFin, option)}>{option}</option>)
                        })}
                    </SelectHora>
                </Row>
                <Row>
                    <Titulo>Día 2</Titulo>
                </Row>
                <Row>
                    <SelectDia onChange={(e)=> setNewData({...newData, diaDos: {...newData.diaDos, horaInicio: e.target.value}})}>
                        <option value="">Dia</option>
                        <option value="Lunes" selected={selected(data.info.diaDos.nombre, "Lunes")}>Lunes</option>
                        <option value="Martes" selected={selected(data.info.diaDos.nombre, "Martes")}>Martes</option>
                        <option value="Miércoles" selected={selected(data.info.diaDos.nombre, "Miércoles")}>Miércoles</option>
                        <option value="Jueves" selected={selected(data.info.diaDos.nombre, "Jueves")}>Jueves</option>
                        <option value="Viernes" selected={selected(data.info.diaDos.nombre, "Viernes")}>Viernes</option>
                    </SelectDia>
                    <SelectHora onChange={(e)=> setNewData({...newData, diaDos: {...newData.diaDos, horaInicio: e.target.value}})}>
                        <option value="">Inicio</option>
                        {horarios.map(option=> {
                            return(<option value={option} selected={selected(data.info.diaDos.horaInicio, option)}>{option}</option>)
                        })}
                    </SelectHora>
                    <SelectHora onChange={(e)=> setNewData({...newData, diaDos: {...newData.diaDos, horaFin: e.target.value}})}>
                        <option value="">Fin</option>
                        {horarios.map(option=> {
                            return(<option value={option} selected={selected(data.info.diaDos.horaFin, option)}>{option}</option>)
                        })}
                    </SelectHora>
                </Row>
                <Botones>
                    <Cerrar onClick={close}>Cancelar</Cerrar>
                    <Confirmar onClick={() => editarHorario(newData)}>Agregar</Confirmar>
                </Botones>
            </Modal>
        </Container>
    )
}

const Container = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 200px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
`

const Input = styled.input`
    outline: none;
    border: 1px solid #F8F9FA;
    color: #989898;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 420px;
    font-weight: 600;
    &::placeholder{
        font-weight: 600;
        color: #989898;
    }
`

const SmallInput = styled(Input)`
    width: 180px;
`

const SmallSelect = styled.select`
    outline: none;
    border: 1px solid #F8F9FA;
    color: #989898;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 222px;
    font-weight: 600;
`

const Modal = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    max-width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 768px){
        margin: 10px;
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
`

const Botones = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 30px;
    @media(max-width: 768px){
        margin-top: 20px;
    }
`

const Cerrar = styled.button`
    cursor: pointer;
    border: none;
    padding: 15px 38px;
    margin-left: auto;
    width: 30%;
    background-color: #C4C4C4;
    border-radius: 14px;
    font-weight: 600;
    color: #fff;
    @media(max-width: 768px){
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 13px;
    }
`

const Confirmar = styled.button`
    cursor: pointer;
    border: none;
    padding: 15px 38px;
    margin-right: auto;
    width: 30%;
    background-color: #7778fe;
    border-radius: 14px;
    font-weight: 600;
    color: #fff;
    @media(max-width: 768px){
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 13px;
    }
`

const Alerta = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`

const Titulo = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #525252;
    margin-bottom: 30px;
    @media(max-width: 768px){
        font-size: 18px;
    }
`

const Row = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    ${Titulo}{
        font-size: 18px;
        margin-bottom: 10px;
    }
`

const SelectDia = styled(SmallSelect)`
    width: 184px;
`

const SelectHora = styled(SmallSelect)`
    width: 120px;
`

export default ModalEditarHorario