import React from 'react'
import styled from 'styled-components'
import Pdf from '../../assets/downloadpdf.svg'
import Pfc from '../../assets/downloadpfc.svg'
import { generarLicencia } from '../../network/lib/admin'
import { axiosClient } from '../../network/api'

function BloqueLicencia({data}) {

  let fecha = new Date(data.createdAt)

  const createPDF = (dni) => {
    generarLicencia(data)

    setTimeout(()=>{
      
      axiosClient.get(`/admin/pdf/${dni}.pdf`, {
        responseType: 'blob',
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        console.log(res.headers)
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${dni}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })

    },1000)

  }

  const downloadPFC = (dni) => {
    axiosClient.get(`/admin/pfc/${dni}.pdf`, {
      responseType: 'blob',
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      console.log(res.headers)
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${dni}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
}

  return (
    <Container>
      <RowDatos>
        <Top>
          <Documento>
            {data.tipoDoc} {data.numDoc}
          </Documento>
          <Nombre>
          Autor: {data.apellido}, {data.nombre}
          </Nombre>
          {
          data.coAutor ?
            <CoAutor>
            Co-autor: {data.coAutor}
            </CoAutor>
          :
            null
          }
        </Top>
        <Bottom>
          <Fecha>{fecha.toLocaleDateString()} a las {fecha.toLocaleTimeString()} hs</Fecha>
        </Bottom>
      </RowDatos>
      <RowBotones>
        <Descargar onClick={() => createPDF(data.numDoc)}>
          <Icon src={Pdf}/>
        </Descargar>
        <Descargar onClick={() => downloadPFC(data.numDoc)}>
          <Icon src={Pfc}/>
        </Descargar>
      </RowBotones>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  border-radius: 10px;
  box-shadow:  2px 2px 4px #fafafa;
  transition: .1s all ease-in-out;
  &:hover{
    transform: scale(1.005);
    transition: .1s all ease-in-out;
  }
`

const RowDatos = styled.div`
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 20px;
`

const RowBotones = styled.div`
  margin-left: auto;
  display: flex;
`

const Top = styled.div`
`

const Bottom = styled.div`
`

const Icon = styled.img`
`

const Descargar = styled.button`
  width: 60px;
  cursor: pointer;
  background-color: transparent;
  border: 0px solid black;
  outline: none;
  padding-right: 20px;
`

const Documento = styled.span`
  margin-right: 30px;
`

const Nombre = styled.span`
  margin-right: 30px;
`

const CoAutor = styled.span`
`

const Fecha = styled.span`
  font-size: 12px;
  color: #8D8D8D;
`

export default BloqueLicencia