import axios from "axios";

let baseURL = 'https://gestion.fapyd.unr.edu.ar';
//let baseURL = 'http://localhost:3001';

export const axiosClient = axios.create({
    baseURL: baseURL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
});

export const axiosCaptcha = axios.create({
  baseURL: baseURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  withCredentials: true,
});