import React from 'react';
import styled from 'styled-components';

export const SearchBox = ({change}) => {
    return (
        <Contain>
            <input type="text" placeholder="Buscar asignatura o cátedra" onChange={(event) => {change(event.target.value)}}></input>
        </Contain>
    )
}

const Contain = styled.div`
    background-color: #F3F2F7;
    padding: 1em;
    padding-top: 0;
    input{
        width: 500px;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
    }
    input:focus{
        outline: none;
    }
    @media (max-width: 1400px){
        input{
            width: 350px;
        }
    }
    @media (max-width: 450px){
        input{
            font-size: 10px;
            
        }
    }
    @media (max-width: 390px){
        input{
            width: 250px;
        }
    }
`