import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ModalAgregarHorario from './Modal.AgregarHorario'

function AgregarHorario() {

    const [openAgregar, setOpenAgregar] = useState(false)

    const close = () => {
        setOpenAgregar(false)
    }

    var horarios = [];

    function setHorarios(horarios) {
        for (var i = 7; i < 24; i++) {
            horarios.push(`${i}:00`);
            horarios.push(`${i}:30`);
        }
        horarios.push('0:00')
        return true
    }
    

    return (
        <>
            {openAgregar ? setHorarios(horarios) ? <ModalAgregarHorario horarios={horarios} close={close}/>: null : null}
            <CTAButton onClick={() => {setOpenAgregar(true)}}>
                + Agregar horario
            </CTAButton>
        </>
    )
}

const CTAButton = styled.button`
    background-color: #7778fe;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    padding: 16px;
    margin-top: auto;
    cursor: pointer;
`

export default AgregarHorario