import React from 'react';
import styled from 'styled-components';
import { checkHora } from './util/function';

export const BurbujaMatematica = ({e, remove}) => {

    var inicio = checkHora(e.dia.horaInicio)
    var fin = checkHora(e.dia.horaFin)

    if (e.dia.nombre==="Lunes"){
        return (
            <ColumnaUno start={inicio} fin={fin} alerta={e.superpuesta}>
                <Contenido>
                <button onClick={() => remove(e)}>x</button>
                    <Materia>
                        Teórico
                    </Materia>
                </Contenido>
            </ColumnaUno>
        )
    }
    if (e.dia.nombre==="Martes"){
        return (
            <ColumnaDos start={inicio} fin={fin} alerta={e.superpuesta}>
                <Contenido>
                <button onClick={() => remove(e)}>x</button>
                    <Materia>
                        Teórico
                    </Materia>
                </Contenido>
            </ColumnaDos>
        )
    }
    if (e.dia.nombre==="Miércoles"){
        return (
            <ColumnaTres start={inicio} fin={fin} alerta={e.superpuesta}>
                <Contenido>
                <button onClick={() => remove(e)}>x</button>
                    <Materia>
                        Teórico
                    </Materia>
                </Contenido>
            </ColumnaTres>
        )
    }
    if (e.dia.nombre==="Jueves"){
        return (
            <ColumnaCuatro start={inicio} fin={fin} alerta={e.superpuesta}>
                <Contenido>
                <button onClick={() => remove(e)}>x</button>
                    <Materia>
                        Teórico
                    </Materia>
                </Contenido>
            </ColumnaCuatro>
        )
    }
    if (e.dia.nombre==="Viernes"){
        return (
            <ColumnaCinco start={inicio} fin={fin} alerta={e.superpuesta}>
                <Contenido>
                <button onClick={() => remove(e)}>x</button>
                    <Materia>
                        Teórico
                    </Materia>
                </Contenido>
            </ColumnaCinco>
        )
    }
}

const ColumnaUno = styled.div`
    display: block;
    margin: 2px auto 10px;
    border-radius: 10px;
    background-color: #383AFF;
    color: white;
    grid-column: 2/3;
    width: 90%;
    grid-row: ${props => props.start}/${props => props.fin};
    border: ${props => props.alerta ? "3px solid #DA1414" : "1px solid #D075AC"};
    animation: blowUpContent .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    @keyframes blowUpContent {
        0% {
          transform:scale(.9);
        }
        100% {
          transform:scale(1);
        }
      }
    @media (max-width:768px){
        margin: 6px auto 2px;
    }
`

const ColumnaDos = styled.div`
    display: block;
    margin: 2px auto 10px;
    border-radius: 10px;
    background-color: #383AFF;
    color: white;
    grid-column: 3/4;
    width: 90%;
    grid-row: ${props => props.start}/${props => props.fin};
    border: ${props => props.alerta ? "3px solid #DA1414" : "1px solid #7778fe"};
    animation: blowUpContent .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    @keyframes blowUpContent {
        0% {
          transform:scale(.9);
        }
        100% {
          transform:scale(1);
        }
      }
    @media (max-width:768px){
        margin: 6px auto 2px;
    }
`

const ColumnaTres = styled.div`
    display: block;
    margin: 2px auto 10px;
    border-radius: 10px;
    background-color: #383AFF;
    color: white;
    grid-column: 4/5;
    width: 90%;
    grid-row: ${props => props.start}/${props => props.fin};
    border: ${props => props.alerta ? "3px solid #DA1414" : "1px solid #7778fe"};
    animation: blowUpContent .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    @keyframes blowUpContent {
        0% {
          transform:scale(.9);
        }
        100% {
          transform:scale(1);
        }
      }
    @media (max-width:768px){
        margin: 6px auto 2px;
    }
`

const ColumnaCuatro = styled.div`
    display: block;
    margin: 2px auto 10px;
    border-radius: 10px;
    background-color: #383AFF;
    color: white;
    grid-column: 5/6;
    width: 90%;
    grid-row: ${props => props.start}/${props => props.fin};
    border: ${props => props.alerta ? "3px solid #DA1414" : "1px solid #7778fe"};
    animation: blowUpContent .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    @keyframes blowUpContent {
        0% {
          transform:scale(.9);
        }
        100% {
          transform:scale(1);
        }
      }
    @media (max-width:768px){
        margin: 6px auto 2px;
    }
`

const ColumnaCinco = styled.div`
    display: block;
    margin: 2px auto 10px;
    border-radius: 10px;
    background-color: #383AFF;
    color: white;
    grid-column: 6/7;
    width: 90%;
    grid-row: ${props => props.start}/${props => props.fin};
    border: ${props => props.alerta ? "3px solid #DA1414" : "1px solid #7778fe"};
    animation: blowUpContent .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    @keyframes blowUpContent {
        0% {
          transform:scale(.9);
        }
        100% {
          transform:scale(1);
        }
      }
    @media (max-width:768px){
        margin: 6px auto 2px;
    }
`

const Contenido = styled.div`
    // padding: 1em;
    text-align: left;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: wrap;
    button{
        margin-left: auto;
        border: 0px;
        background: transparent;
        cursor: pointer;
        color: #fff;
        margin-bottom: 0;
        position: absolute;
        left: 85%;
    }
    @media (max-width: 1200px){
        font-size: 13px;
    }
    @media (max-width: 1100px){
        font-size: 12px;
        word-break: break-all;
    }
    @media (max-width: 850px){
        font-size: 10px;
        word-break: break-all;
        button {
            left: 80%;
        }
    }
    @media (max-width: 450px){
        font-size: 8px;
        button {
            left: 70%;
        }
    }
`

const Materia = styled.div`
    font-weight: 600;
    padding: .5em 1em;
    padding-bottom: 0;
    @media (max-width: 650px){
        padding: 1.5em .5em .5em;
    }
    @media (max-width: 450px){
        padding-top: 2em;
    }
    word-break: normal;
`