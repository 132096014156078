import { axiosClient } from "../api";

export const loginLicencia = (data) => {
    return (axiosClient.post('/licencia/login', {
        legajo: data.letra + "-" + data.numeros + "/" + data.numero,
    }))
}

export const checkLoginLicencia = () => {
    return (axiosClient.get('/licencia/login'))
}

export const logoutLicencia = () => {
    return (axiosClient.get('/licencia/logout'))
}

export const enviarFormularioLicencia = (data) => {
    return (axiosClient.post('/licencia/enviar', data))
}

export const enviarPFCLicencia = (form, config) => {
    return (axiosClient.post('/licencia/pfc', form, config))
}