import React from 'react';
import ReactDOM from 'react-dom';
import Login from './pages/Ingresar/Login'
import Home from './pages/Home/Home'
import Licencia from './pages/Licencia/Licencia'
import Inicio from './pages/Licencia/Inicio'
import Registrar from './pages/Registrar/Registrar'
import Enviado from './pages/Licencia/Enviado';
import Listado from './pages/Admin/Listado';
import Admin from './pages/Admin/Admin';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Cuadro from './pages/CuadroHorario/Cuadro';
import Certificado from './pages/Certificado/Certificado';

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path='/'> 
        <Home />
      </Route>
      <Route exact path='/cuadro'>
        <Cuadro />
      </Route>
      <Route exact path='/admin'>
        <Login />
      </Route>
      <Route exact path='/certificado'>
        <Certificado />
      </Route>
      <Route exact path='/admin/registrar'>
        <Registrar />
      </Route>
      <Route exact path='/admin/dashboard'>
        <Admin />
      </Route>
      <Route exact path='/admin/listado'>
        <Listado />
      </Route>
      <Route exact path='/licencia'>
        <Licencia />
      </Route>
      <Route exact path='/licencia/home'>
        <Inicio />
      </Route>
      <Route exact path='/licencia/enviado'>
        <Enviado />
      </Route>
    </Switch>
  </Router>,
  document.getElementById('root')
);