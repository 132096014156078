import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

export const SelectSubject = ({change, year, turno, data}) => {    

    return (
        <div>
            <Label>Asignatura</Label>
            <Contain>
                {(year===0) && 
                    <React.Fragment>
                        <select name="asignatura">
                            <option value="">Seleccione un año</option>
                        </select>
                    </React.Fragment>
                }
                {(year===1) &&
                    <React.Fragment>
                        <select name="asignatura" onChange={(e)=>change(e.target.value)}>
                            <option value="">Seleccione una asignatura</option>
                            <option value="Matemática I - Teórico">Matemáticas I - Teórico</option>
                            <option value="Matemática I">Matemáticas I - Práctico</option>
                            <option value="Intr. a la Arquitectura">Introducción a la Arquitectura</option>
                            <option value="Epistemología I">Epistemología I</option>
                            <option value="Física">Física</option>
                            <option value="Expresión Gráfica I">Expresión Gráfica I</option>
                            <option value="Materialidad I">Materialidad I</option>
                        </select>
                    </React.Fragment>
                }
                {(year===2) &&
                    <React.Fragment>
                        <select name="asignatura" onChange={(e)=>change(e.target.value)}>
                            <option value="">Seleccione una asignatura</option>
                            <option value="Análisis Proyectual I">Análisis Proyectual I</option>
                            <option value="Geometría Descriptiva">Geometría Descriptiva</option>
                            <option value="Estática y resistencia">Estática y resistencia de los materiales</option>
                            <option value="Historia de la Arq. I">Historia de la Arquitectura I</option>
                            <option value="Expresión Gráfica II">Expresión Gráfica II</option>
                            <option value="Materialidad II">Materialidad II</option>
                        </select>
                    </React.Fragment>
                }
                {(year===3) &&
                    <React.Fragment>
                        <select name="asignatura" onChange={(e)=>change(e.target.value)}>
                            <option value="">Seleccione una asignatura</option>
                            <option value="Análisis Proyectual II">Análisis Proyectual II</option>
                            <option value="Matemática II">Matemáticas II</option>
                            <option value="Intr. al Urbanismo">Introducción al Urbanismo</option>
                            <option value="Diseño de Estructuras I">Diseño de Estructuras I</option>
                            <option value="Historia de la Arq. II">Historia de la Arquitectura II</option>
                            <option value="Materialidad III">Materialidad III</option>
                        </select>
                    </React.Fragment>
                }
                {(year===4) &&
                    <React.Fragment>
                        <select name="asignatura" onChange={(e)=>change(e.target.value)}>
                            <option value="">Seleccione una asignatura</option>
                            <option value="Análisis Urbanístico">Análisis Urbanístico</option>
                            <option value="Proyecto Arquitectónico I">Proyecto Arquitectónico I</option>
                            <option value="Producción Edilicia I">Producción Edilicia I</option>
                            <option value="Diseño de Estructuras II">Diseño de Estructuras II</option>
                            <option value="Historia de la Arq. III">Historia de la Arquitectura III</option>
                        </select>
                    </React.Fragment>
                }
                {(year===5) &&
                    <React.Fragment>
                        <select name="asignatura" onChange={(e)=>change(e.target.value)}>
                            <option value="">Seleccione una asignatura</option>
                            <option value="Epistemología II">Epistemología II</option>
                            <option value="Intervención Urbanística">Intervención Urbanística</option>
                            <option value="Producción Edilicia II">Producción Edilicia II</option>
                            <option value="Proyecto Arquitectónico II">Proyecto Arquitectónico II</option>
                        </select>
                    </React.Fragment>
                }
                {(year===6) &&
                    <React.Fragment>
                        <select name="asignatura" onChange={(e)=>change(e.target.value)}>
                            <option value="">Seleccione una asignatura</option>
                            <option value="Inglés I">Inglés I</option>
                            <option value="Inglés II">Inglés II</option>
                            <option value="Proyecto Final de Carrera">Proyecto Final de Carrera</option>
                        </select>
                    </React.Fragment>
                }
            </Contain>
        </div>
    )
}

const Contain = styled.div`
    background-color: #F3F2F7;
    width: 535px;
    padding: 1em;
    border-radius: 20px;
    select{
        padding: 0 .5em;
        width: 100%;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        color: #6F6F6F;
    }
    select:focus{
        outline: none;
    }
    @media (max-width: 1400px){
        width: 400px;
    }
    @media (max-width: 450px){
        select{
            font-size: 10px;
        }
        width: 330px;
        border-radius: 14px;
    }
    @media (max-width: 390px){
        width: 260px;
    }
`
const Label = styled.div`
    display: block;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 1em;
    @media (max-width: 450px){
        font-size: 11px;
    }
`