import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { GlobalStyle } from '../Admin.Styled.js'
import Refresh from '../../../components/Admin/Refresh.js'
import AgregarHorario from '../../../components/Admin/AgregarHorario.js'
import { getHorarios } from '../../../network/lib/admin.js'
import BloqueHorario from '../../../components/Admin/BloqueHorario.js'
import ReactPaginate from 'react-paginate';
import '../../../assets/styles.css'

function Horarios() {

    const [listado, setListado] = useState([])

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10)

    useEffect(() => {
        getHorarios().then((res)=>{
            setListado(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }, [])

    useEffect(()=>{
        setPageCount(Math.ceil(listado.length / itemsPerPage));
    })

    const endOffset = itemOffset + itemsPerPage;
    const currentItems =  listado.slice(itemOffset, endOffset)

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listado.length;
        setItemOffset(newOffset);
    };

    return (
        <Container>
            <GlobalStyle />
                <ContHeader>
                    <Titulo>
                        Horarios
                    </Titulo>
                    <Acciones>
                        <Refresh setListado={setListado} get={getHorarios}/>
                        <AgregarHorario />
                    </Acciones>
                </ContHeader>
            <Bloque>
                <Header>
                    <Campo>
                        Año
                    </Campo>
                    <Campo>
                        Nombre
                    </Campo>
                    <Campo>
                        Cátedra
                    </Campo>
                    <Campo>
                        Docente
                    </Campo>
                    <Campo>
                        Día
                    </Campo>
                    <Campo>
                        Horario
                    </Campo>
                    <Campo>
                        Día 2
                    </Campo>
                    <Campo>
                        Horario
                    </Campo>
                    <Campo>
                        Turno
                    </Campo>
                    <Campo>
                        Acciones
                    </Campo>
                </Header>
                <ListaHorarios>
                    {currentItems && currentItems.map((horario)=>{
                        return(<BloqueHorario data={horario}/>)
                    })}
                </ListaHorarios>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </Bloque>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 80%;
    flex-direction: column;
`

const ContHeader = styled.div`
    display: flex;
`

const Titulo = styled.div`
    font-size: 20px;
    color: #7778fe;
    margin-left: 100px;
    margin-top: 100px;
    font-weight: bold;
`

const Acciones = styled.div`
    display: flex;
    margin-left: auto;
`

const Header = styled.div`
    display: flex;
    background-color: #F8F9FA;
    width: 100%;
`

const Campo = styled.div`
    width: 100px;
    &:nth-child(1){
      width: 40px;
    }
    padding: 6px;
    padding-left: 16px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #989898;
`

const Bloque = styled.div`
    display: flex;
    width: 80%;
    margin: 100px;
    margin-right: 0;
    margin-top: 20px;
    flex-direction: column;
    height: 800px;
`

const ListaHorarios = styled.div`
    font-size: 13px;
    font-weight: bold;
`

export default Horarios
