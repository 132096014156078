import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { getEstudiantes } from '../../../network/lib/admin.js'
import { GlobalStyle } from '../Admin.Styled.js'
import BloqueEstudiante from '../../../components/Admin/BloqueEstudiante.js'
import BuscarEstudiante from '../../../components/Admin/BuscarEstudiante.js'
import AgregarEstudiante from '../../../components/Admin/AgregarEstudiante.js'
import Refresh from '../../../components/Admin/Refresh.js'

function Estudiantes() {

  const [listado, setListado] = useState([])

  useEffect(() => {
      getEstudiantes().then((res)=>{
          setListado(res.data)
      }).catch((err)=>{
          console.log(err)
      })
  }, [])

  const buscar = (criteria)=>{
    if(criteria !== ''){
      const resultadoBusqueda = listado.filter((item)=>{
        return item.firstName.toLowerCase().includes(criteria.toLowerCase()) || item.lastName.toLowerCase().includes(criteria.toLowerCase()) || item.legajo.toLowerCase().includes(criteria.toLowerCase())
      })
      setListado(resultadoBusqueda)
    }else{
      getEstudiantes().then((res)=>{
        setListado(res.data)
      }).catch((err)=>{
          console.log(err)
      })
    }
  }

  return (
      <Container>
        <GlobalStyle />
          <ContHeader>
            <Titulo>
              Estudiantes
            </Titulo>
            <Acciones>
              <BuscarEstudiante change={buscar}/>
              <Refresh setListado={setListado} get={getEstudiantes}/>
              <AgregarEstudiante />
            </Acciones>
          </ContHeader>
        <Bloque>
          <Header>
            <Campo>
              Legajo
            </Campo>
            <Campo>
              Nombre
            </Campo>
            <Campo>
              Apellido
            </Campo>
            <Campo>
              Creado
            </Campo>
            <Campo>
              Enviado
            </Campo>
            <Campo>
              Acciones
            </Campo>
          </Header>
          <ListaEstudiantes>
            {listado.map((estudiante)=>{
                return(<BloqueEstudiante data={estudiante}/>)
            })}
          </ListaEstudiantes>
        </Bloque>
      </Container>
  )
}

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 80%;
    flex-direction: column;
`

const ContHeader = styled.div`
    display: flex;
`

const Titulo = styled.div`
    font-size: 20px;
    color: #7778fe;
    margin-left: 100px;
    margin-top: 100px;
    font-weight: bold;
`

const Dato = styled.div`
    font-size: 14px;
    margin-left: 100px;
    margin-top: 10px;
`

const Acciones = styled.div`
    display: flex;
    margin-left: auto;
`


const Header = styled.div`
    display: flex;
    background-color: #F8F9FA;
    width: 100%;
`

const Campo = styled.div`
    width: 180px;
    &:nth-child(1){
      width: 100px;
    }
    padding: 6px;
    padding-left: 16px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #989898;
`

const Bloque = styled.div`
    display: flex;
    width: 80%;
    margin: 100px;
    margin-right: 0;
    margin-top: 20px;
    flex-direction: column;
    height: 800px;
`

const ListaEstudiantes = styled.div`
    font-size: 13px;
    font-weight: bold;
    height: 70vh;
    overflow-y: scroll;
`

export default Estudiantes