import styled from "styled-components"
import { createGlobalStyle } from 'styled-components'
import '../../assets/styles.css';
import hero from '../../assets/Feed.jpg';
import Fapyd from '../../assets/fapyd.svg';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    height:100vh;
    margin: 0;
    padding: 0;
    @media(max-width: 768px){
        overflow-y: hidden;
    }
`

export const Navbar = styled.div`
  background-color: #7778fe;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1.5em;
  font-family: 'Montserrat', 'sans-serif';
  img {
    height: 40px;
  }
`

export const Izquierda = styled.div`
    width: 100%;
    height: 100vh;
    background: url(${hero}) no-repeat;
    background-size: cover;
    @media(max-width: 1440px){
        width: 70%;
    }
    @media(max-width: 768px){
        display: none;
    }
`

export const Derecha = styled.div`
    width: 80%;
    background-color: #7778FE;
    @media(max-width: 768px){
        width: 100%;
    }
`

export const Ingresar = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: auto;
    width: fit-content;
    //background-color: #FDFDFD;
    border-radius: 20px;
    padding: 30px 60px 50px;
    //box-shadow: 2px 4px 4px rgba(122, 122, 122, 0.12);
    label {
        margin: 1em 0 10px;
        margin-left: 0;
        color: #fff;
        font-weight: 500;
        margin-top: 100px;
    }
    @media(max-width: 768px){
        margin-left: auto;
        margin-right: auto;
    }
    @media(max-width: 500px){
        padding: 0;
    }
    @media(max-width: 450px){
        label{
            margin-left: auto;
            margin-right: auto;
        }
    }
`

export const Titulo = styled.div`
    font-weight: 600;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    span{
        font-size: 36px;
        display: block;
        margin-bottom: 16px;
        margin-top: 20px;
    }
    @media(max-width: 450px){
        text-align: center;
        font-size: 20px;
        span{
            text-align: center;
            line-height: 36px;
            padding: 0 20px;
            margin-top: 0px;
            font-size: 34px;
        }
    }
`


export const ButtonIngresar = styled.button`
    width: 150px;
    padding: 10px 30px;
    background: transparent;
    border-radius: 30px;
    text-transform: uppercase;
    border: 2px solid #DEFF97;
    font-weight: bold;
    color: #fff;
    margin-left: auto;
    float: right;
    margin-top: 30px;
    font-size: 16px;
    cursor: pointer;
    transition: all .3s ease-out;
    :hover{
        background-color: #deff97;
        transform: translate(0, -5px);
        color: #000;
    }
`

export const ButtonContainer = styled.div`
    width: 380px;
    @media(max-width: 450px){
        width: auto;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }
`

export const Bottom = styled.div`
    margin-left: 110px;
    margin-top:5%;
    width: 400px;
    display:flex;
    position-absolute;
    justify-content: space-between;
    bottom:1em;
    img:nth-child(2){
        float: right;
    }
    @media(max-width: 768px){
        margin-top:0%;
        margin-bottom:10em;
        display: flex;
        justify-content: space-around;
        width: auto;
        margin-left: 2em;
        margin-right: 2em;
        padding-bottom:
        img:nth-child(2){
            margin-left: auto;
        }
    }
    @media(max-width: 450px){
        padding: 0 20px;
    }
`

export const Message = styled.div`
@keyframes animein {
    from {opacity:0}
    to {opacity:1}
}
    display:flex;
    color: #fff;
    border-radius:2em;
    align-self: center;
    padding:1.5em;
    width: 300px;
    margin-top: 2em;
    background-color: rgba(0,0,0,.3);
    animation: animein .3s;
`

// Inicio

export const Contenedor = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 25%;
    padding: 0;
    padding-bottom: 100px;
    @media (max-width: 1440px){
        margin: 0 15%;
    }
`


export const TituloTop = styled.div`
    display: block;
    padding: 14px 10%;
    background-color: #616175;
    color: #fff;
    font-weight: 700;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 24px;
    @media (max-width: 768px){
        font-size: 20px;
        text-align: center;
    }
`

export const Logout = styled.div`
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    color: #fff;
`

export const Texto = styled.div`
    font-size: 16px;
    @media (max-width: 768px){
            font-size: 14px;
        }
    a{
        text-decoration: none;
        color: #7778fe;
        font-weight: bold;
    }
    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
    p{
        margin-bottom: 50px;
    }
    i{
        margin-top: 20px;
        font-size: 14px;
    }
`

export const Dato = styled.div`
    display: block;
    font-weight: 700;
`

export const Small = styled.div`
    text-align: right;
    font-size:8pt;
    `

export const Input = styled.input`
    display: block;
    outline: none;
    width: 100%;
    border: none;
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid black;
    margin-top: 10px;
    margin-bottom: 20px;
`

export const Radio = styled.input`
    outline: none;
    margin-top: 10px;
    margin-bottom: 10px;
`

export const Label = styled.div`
    display: flex;
    flex-direction: column;
    p{
        margin: 0;
    }
    p:nth-child(1){
        margin-top: 10px;
    }
    p:nth-child(2){
        margin-bottom: 20px;
    }
`


export const Motivo = styled.div`
    
`

export const TextArea = styled.textarea`
    border-radius: .3em;
    display: block;
    outline: none;
    width: 100%;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 20px;
    height: 100px;
    margin-top: 20px;
`

export const Puntos = styled.div`
    margin-bottom: 50px;
`

export const Paso = styled.div`
    display: block;
    /* padding: 14px 100px;
    background-color: #7778fe; */
    color: #000;
    font-weight: 700;
    border-radius: 20px;
    /* margin-left: auto; */
    margin-right: auto;
    margin-bottom: 20px;
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 24px;
`

export const Licencias = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 60px;
`

export const Licencia = styled.div`
    display: block;
    width: 100%;
    border: 1px solid rgba(122, 122, 122, 0.08);
    box-shadow: 2px 4px 4px rgba(122, 122, 122, 0.08);
    cursor: pointer;
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 20px;
    h1{
        font-weight: 600;
        font-size: 16px;
        margin-top: 20px;
    }
    cursor: pointer;
    img {
        width: 125px;
        height: 44px;
        margin-bottom: 20px;
    }
    p{
        margin-bottom: 10px;
    }
`

export const Select = styled.select`
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
`

export const Botones = styled.div`
    display: flex;
    flex-direction: row;
`

export const Siguiente = styled.div`
    padding: 10px 30px;
    border-radius: 20px;
    margin-left: auto;
    color: #fff;
    background-color: #7778fe;
    cursor: pointer;
    @media (max-width: 768px){
        padding: 8px 20px;
        font-size: 14px;
    }
    transition: all .3s ease-out;
    :hover{
        transform: translate(0, -5px);
    }
`

export const Anterior = styled.div`
    padding: 10px 30px;
    border-radius: 20px;
    margin-right: auto;
    color: #fff;
    background-color: #7778fe;
    cursor: pointer;
    @media (max-width: 768px){
        padding: 8px 20px;
        font-size: 14px;
    }
    transition: all .3s ease-out;
    :hover{
        transform: translate(0, -5px);
    }
`

export const Alerta = styled.div`
    color: red;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
`

export const ContenedorIngresar = styled.div`
    width: 300px;
    display: flex;
    padding: 20px 40px;
    border: 1px solid #f5f5f5;
    background-color: #F5F5F5;
    border-radius: 40px;
    margin-top: 10px;
    outline: none;
    input:nth-child(1), input:nth-child(2), input:nth-child(4){
        width: 10px;
    }
    input {
        width: 30px;
        padding: 0;
        background: transparent;
        border: none;
    }
    input[type="text"]:focus{
        outline: none;
    }
    @media(max-width: 450px){
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }
`

export const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
`