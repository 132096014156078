import React, {useEffect} from 'react'
import { Contenedor, TituloTop, Texto, Puntos, Siguiente, GlobalStyle } from '../Licencia.Styled';
import icon from '../../../assets/mdi_arrow-right-circle.svg'

function Acuerdo({nextStep}) {

    useEffect(() => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
    }, [])

    return (
        <div>
            <Contenedor>
                <TituloTop>Acuerdo de cesión no exclusiva de derechos</TituloTop>
                <Texto>
                    Repositorio Hipermedial UNR es el repositorio institucional de acceso abierto de la Universidad Nacional de Rosario creado para archivar,
                    preservar y distribuir digitalmente la creación intelectual de esta universidad en acceso libre y gratuito y resguardar a su vez los derechos
                    de los autores.
                    <br></br><br></br>
                    El presente acuerdo se establece entre la Universidad Nacional de Rosario (en adelante "la UNIVERSIDAD") y el AUTOR.
                    <br></br><br></br>
                    El AUTOR y titular de los derechos de autor de LA OBRA depositada o la persona delegada para hacerlo:
                    <br></br><br></br>
                    <Puntos>
                        <img src={icon} />Garantiza a la UNIVERSIDAD el derecho no exclusivo a almacenar en servidores, reproducir, transformar (como se define más abajo) y
                        a realizar la comunicación pública de LA OBRA de forma libre y gratuita a todo público en formato digital en el sitio web del 
                        <a href="http://rephip.unr.edu.ar/" target="_blank"> Repositorio Hipermedial UNR.</a>
                        <br></br><br></br>
                        <img src={icon} />Acepta que la UNIVERSIDAD puede, sin cambiar el contenido, convertir LA OBRA a cualquier otro formato o medio con el propósito de preservación y para su correcta visualización en la web.
                        <br></br><br></br>
                        <img src={icon} />Acepta que la UNIVERSIDAD pueda guardar más de una copia de LA OBRA depositada con propósitos de seguridad, copias de reemplazo y preservación.
                        <br></br><br></br>
                        <img src={icon} />Si el material enviado contiene partes de las cuales EL AUTOR no es el titular de los derechos de propiedad intelectual, este manifiesta que ha obtenido permiso irrestricto del titular/es de estos derechos para otorgar a la UNIVERSIDAD los derechos requeridos por este acuerdo, y que esta tercera parte es claramente identificable en el texto o contenido de LA OBRA depositada
                        <br></br><br></br>
                        <img src={icon} />Declara que al aceptar el presente acuerdo no infringe ningún derecho de terceros, sea de propiedad industrial, intelectual, secreto comercial o cualquier otro derecho susceptible de protección jurídica.
                        <br></br><br></br>
                        <img src={icon} />Acepta que las gestiones para la eventual inscripción de LA OBRA ante el Registro de Derechos de Autor son de su exclusiva responsabilidad.
                        <br></br><br></br>
                        <img src={icon} />En caso de que LA OBRA depositada se base en un trabajo que fue patrocinado o pagado por alguna agencia u organización distinta a la UNIVERSIDAD, manifiesta que ha cumplido con todas las obligaciones por contrato o acuerdo con esta/s organización/es o agencia/s.
                        <br></br><br></br>
                        <img src={icon} />Declara que la UNIVERSIDAD se encuentra libre de todo tipo de responsabilidad, sea civil, administrativa o penal y que asume toda responsabilidad frente a cualquier reclamo o demanda por parte de terceros de manera exclusiva.
                    </Puntos>
                </Texto>
                <Siguiente onClick={nextStep}>Siguiente</Siguiente>
            </Contenedor>
        </div>
    )
}


export default Acuerdo
