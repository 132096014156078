import React, {useState} from 'react'
import styled from 'styled-components'
import Dashboard from '../../components/Admin/Dashboard'
import { GlobalStyle } from './Admin.Styled.js'
import Licencias from './Dashboard/Admin.Licencias'
import Estudiantes from './Dashboard/Admin.Estudiantes'
import Horarios from './Dashboard/Admin.Horarios'

function Admin() {

    const [step, setStep] = useState(1)

    function evaluateStep(step) {
        switch(step) {
            case 1:
                return (
                    <Licencias />
                );
            case 2:
                return (
                    <Estudiantes />
                );
            case 3:
                return (
                    <Horarios />
                );
        }
    }

  return (
    <Container>
        <GlobalStyle />
        <Dashboard change={setStep}/>
        {evaluateStep(step)}
    </Container>
  )
}

const Container = styled.div`
    display: flex;    
    height: 100vh;
`

export default Admin