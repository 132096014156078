import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { getLicencias } from '../../../network/lib/admin.js'
import { GlobalStyle } from '../Admin.Styled.js'
import BloqueLicencia from '../../../components/Admin/BloqueLicencia.js'

function Licencias() {

    const [listado, setListado] = useState([])

    useEffect(() => {
        getLicencias().then((res)=>{
            setListado(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }, [])
    

    return (
        <Container>
            <GlobalStyle />
                <Titulo>
                    Licencias de depósito generadas
                </Titulo>
                <Dato>
                    {listado.length} licencias generadas.
                </Dato>
            <Bloque>
                {listado.length && 
                    listado.map((lic)=>{
                        return(<BloqueLicencia data={lic}/>)
                })}
            </Bloque>
        </Container>
      )
    }
    
const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 80%;
    flex-direction: column;
`

const Titulo = styled.div`
    font-size: 20px;
    color: #7778fe;
    margin-left: 100px;
    margin-top: 100px;
    font-weight: bold;
`

const Dato = styled.div`
    font-size: 14px;
    margin-left: 100px;
    margin-top: 10px;
`


const Bloque = styled.div`
    display: flex;
    width: 70%;
    margin: 100px;
    margin-right: 0;
    margin-top: 20px;
    flex-direction: column;
    height:800px;
    overflow-y: scroll;
    overflow-x: hidden;
`

export default Licencias