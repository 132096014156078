import React, { useState } from 'react'
import styled from 'styled-components'
import ModalEditarEstudiante from './Modal.EditarEstudiante'
import ModalBorrarEstudiante from './Modal.BorrarEstudiante'

function BloqueEstudiante({data}) {

    const [openEditar, setOpenEditar] = useState(false)
    const [openBorrar, setOpenBorrar] = useState(false)

    const close = () => {
      setOpenEditar(false)
      setOpenBorrar(false)
    }

    const options = {  day: 'numeric' , month: 'numeric', year: 'numeric' };

  return (
      <>
      {openEditar ? <ModalEditarEstudiante data={data} close={close}/>: null}
      {openBorrar ? <ModalBorrarEstudiante data={data} close={close}/>: null}
        <Container>
            <Campo>
                {data.legajo}
            </Campo>
            <Campo>
                {data.firstName}
            </Campo>
            <Campo>
                {data.lastName}
            </Campo>
            <Campo>
                {new Date(data.createdAt).toLocaleDateString(undefined, options)}
            </Campo>
            <Campo>
                {data.updatedAt ? new Date(data.updatedAt).toLocaleDateString(undefined, options) : ''}
            </Campo>
            <Campo>
                <Editar onClick={() => {setOpenEditar(true)}}>Editar</Editar>
                <Borrar onClick={() => {setOpenBorrar(true)}}>Borrar</Borrar>
            </Campo>
        </Container>
      </>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #F8F9FA;
`

const Campo = styled.div`
    width: 180px;
    &:nth-child(1){
    width: 100px;
    }
    padding: 6px;
    padding-left: 16px;
    font-weight: 700;
    color: #000;
`

const Editar = styled.button`
    cursor: pointer;
    background-color: #7778fe;
    color: #fff;
    font-weight: 600;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
`

const Borrar = styled.button`
    margin-left: 20px;
    cursor: pointer;
    background-color: #FF0000;
    color: #fff;
    font-weight: 600;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
`


export default BloqueEstudiante