import styled from "styled-components"
import { createGlobalStyle } from 'styled-components'
import '../../assets/styles.css';

export const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background-color: #7778fe;
        overflow-y: hidden;
    }
`

export const Titulo = styled.h3`
    font-weight: 600;
    font-size: 32px;
    color: #fff;
    text-align: center;
    margin-top: auto;
    @media(max-width: 450px){
        font-size: 24px;
        margin-top: 150px;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`

export const Continuar = styled.a`
    margin-bottom: auto;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 16px 34px;
    font-size: 16px;
    border-radius: 30px;
    border: 2px solid #deff97;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    font-weight: 600;
    transition: all .3s ease-out;
    text-decoration: none;
    :hover{
        background-color: #deff97;
        transform: translate(0, -5px);
        color: #000;
    }
    @media(max-width: 450px){
        padding: 16px 24px;
        font-size: 14px;
        text-align: center;
        border-radius: 50px;
    }
`