import React from 'react'
import RefreshIcon from '../../assets/refresh.svg'
import styled from 'styled-components'

function Refresh({setListado, get}) {

    const refresh = () => {
        get().then((res)=>{
            setListado(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <Icon src={RefreshIcon} onClick={()=> refresh()}/>
    )
}

const Icon = styled.img`
    width: 20px;
    margin: 16px;
    margin-top: auto;
    cursor: pointer;
`

export default Refresh