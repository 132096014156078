import styled from "styled-components"
import { createGlobalStyle } from 'styled-components'


export const Navbar = styled.div`
  background-color: #7778FE;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 1.5em;
  font-family: 'Montserrat', 'sans-serif';
  img {
    height: 40px;
  }
  }
`

export const Cards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 150px;
  @media (max-width: 1200px) {
    margin:2em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
  }
  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
  }
`

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
`