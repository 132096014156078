import React, {useEffect, useState} from 'react'
import { Contenedor, Paso, Texto, Licencias, Licencia, Anterior, Siguiente, Botones } from '../Licencia.Styled';
import uno from '../../../assets/licencias/11.png'
import dos from '../../../assets/licencias/21.png'
import tres from '../../../assets/licencias/31.png'
import cuatro from '../../../assets/licencias/41.png'
import cinco from '../../../assets/licencias/51.png'
import seis from '../../../assets/licencias/61.png'

function Atribucion({setData, data, nextStep, prevStep}) {

    const opciones = [
        {
            'h1' : 'Atribución (by):',
            'value' : 'By',
            'p' : 'Se permite cualquier explotación de LA OBRA, incluyendo la explotación con fines comerciales y la creación de obras derivadas, la distribución de las cuales también está permitida sin ninguna restricción.',
            'img' : uno,
        },
        {
            'h1' : 'Atribución - Compartir Igual (by-sa):',
            'value' : 'By-Sa',
            'p' : 'Se permite el uso comercial de LA OBRA y de las posibles obras derivadas, la distribución de las cuales se debe hacer con una licencia igual a la que regula LA OBRA original.',
            'img' : dos,
        },
        {
            'h1' : 'Atribución - No Comercial (by-nc):',
            'value' : 'By-Nc',
            'p' : 'Se permite la generación de obras derivadas siempre que no se haga con fines comerciales. Tampoco se puede utilizar LA OBRA original con fines comerciales.',
            'img' : tres,
        },
        {
            'h1' : 'Atribución - No Comercial - Compartir Igual (by-nc-sa):',
            'value' : 'By-Nc-Sa',
            'p' : 'No se permite un uso comercial de LA TESIS/TESINA original ni de las posibles obras derivadas, la distribución de las cuales se debe hacer con una licencia igual a la que regula LA OBRA original.',
            'img' : cuatro,
        },
        {
            'h1' : 'Atribución - Sin Obra Derivada (by-nd):',
            'value' : 'By-Nd',
            'p' : 'Se permite el uso comercial de LA OBRA pero no la generación de obras derivadas.',
            'img' : cinco,
        },
        {
            'h1' : ' Atribución - No Comercial - Sin Obra Derivada (by-nc-nd):',
            'value' : 'By-Nc-Nd',
            'p' : 'No se permite un uso comercial de LA OBRA original ni la generación de obras derivadas.',
            'img' : seis,
        }   
    ]

    const [seleccionado, setSeleccionado] = useState(opciones[0].value);
    const [radioSeleccionado, setRadioSeleccionado] = useState('<input type="radio" name="licencia_cc" value="By">')

    const seleccionar = (selected) =>{
        console.log(selected)
        setSeleccionado(selected)
        setRadioSeleccionado(`<input type="radio" name="licencia_cc" value="${selected}">`)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
    }, [])

    const selected = (val) => {
        if (data.licencia === val) {
            // console.log(val)
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <Contenedor>
                <Paso> Paso 1 · Tipo de atribución </Paso>
                    <Texto>
                    En virtud del carácter no exclusivo del presente acuerdo, EL AUTOR podrá comunicar y dar publicidad libremente a LA OBRA, en cualquier versión y por todos los medios que considere oportunos. Asimismo el titular de los derechos de autor podrá solicitar un retraso temporal (periodo de embargo) de la puesta a disposición pública de LA OBRA.
                    <br></br><br></br>El AUTOR podrá solicitar el retiro de LA OBRA del Repositorio Hipermedial UNR solamente por causas debidamente justificadas y acreditadas.
                    <br></br><br></br>En cuanto a LA OBRA, será puesta a disposición pública mediante su inclusión en el Repositorio Hipermedial UNR, bajo las siguientes condiciones elegidas por EL AUTOR <b>(marcar lo que corresponda):</b><br></br><br></br>
                        <Licencias>
                            {
                            opciones.map((opcion,i)=>{
                            return(
                                <Licencia key={i} onClick={()=>{seleccionar(opcion.value);setData({...data,licencia:opcion.value})}} className={seleccionado === opcion.value? 'red' : ''}>
                                    <h1><input type="radio" name="licencia_cc" value={opcion.value} checked={seleccionado === opcion.value? true : false} readOnly></input>{opcion.h1}</h1>
                                    <p>{opcion.p}</p>
                                    <img src={opcion.img} />
                                </Licencia>
                            )
                            })
                            }
                        </Licencias>
                    <p>En función de las condiciones elegidas por EL AUTOR, el Repositorio Hipermedial UNR seleccionará una licencia Creative Commons compatible que se incluirá y aplicará a la obra. La UNIVERSIDAD no asumirá responsabilidad alguna por otros usos no autorizados de LA OBRA o contrarios a la legislación vigente.</p>
                    </Texto>
                    <Botones>
                        <Anterior onClick={prevStep}>Anterior</Anterior>
                        <Siguiente onClick={nextStep}>Siguiente</Siguiente>
                    </Botones>
            </Contenedor>
        </div>
    )
}

/*

    return (
        <div>
            <Contenedor>
                <Paso> Paso 1 · Tipo de atribución </Paso>
                    <Texto>
                    En virtud del carácter no exclusivo del presente acuerdo, EL AUTOR podrá comunicar y dar publicidad libremente a LA OBRA, en cualquier versión y por todos los medios que considere oportunos. Asimismo el titular de los derechos de autor podrá solicitar un retraso temporal (periodo de embargo) de la puesta a disposición pública de LA OBRA.
                    <br></br><br></br>El AUTOR podrá solicitar el retiro de LA OBRA del Repositorio Hipermedial UNR solamente por causas debidamente justificadas y acreditadas.
                    <br></br><br></br>En cuanto a LA OBRA, será puesta a disposición pública mediante su inclusión en el Repositorio Hipermedial UNR, bajo las siguientes condiciones elegidas por EL AUTOR <b>(marcar lo que corresponda):</b><br></br><br></br>
                        <Licencias>
                            <Licencia>
                                <h1><input type="radio" name="licencia_cc" value="By" onChange={(e) => setData({...data, licencia: e.target.value})} checked={selected("By")}></input>Atribución (by):</h1>
                                <p>Se permite cualquier explotación de LA OBRA, incluyendo la explotación con fines comerciales y la creación de obras derivadas, la distribución de las cuales también está permitida sin ninguna restricción.</p>
                                <img src={uno} />
                            </Licencia>
                            <Licencia>
                                <h1><input type="radio" name="licencia_cc" value="By-Sa" onChange={(e) => setData({...data, licencia: e.target.value})} checked={selected("By-Sa")}></input>Atribución - Compartir Igual (by-sa):</h1>
                                <p>Se permite el uso comercial de LA OBRA y de las posibles obras derivadas, la distribución de las cuales se debe hacer con una licencia igual a la que regula LA OBRA original.</p>
                                <img src={dos} />
                            </Licencia>
                            <Licencia>
                                <h1><input type="radio" name="licencia_cc" value="By-Nc" onChange={(e) => setData({...data, licencia: e.target.value})} checked={selected("By-Nc")}></input>Atribución - No Comercial (by-nc):</h1>
                                <p>Se permite la generación de obras derivadas siempre que no se haga con fines comerciales. Tampoco se puede utilizar LA OBRA original con fines comerciales.</p>
                                <img src={tres} />
                            </Licencia>
                            <Licencia>
                                <h1><input type="radio" name="licencia_cc" value="By-Nc-Sa" onChange={(e) => setData({...data, licencia: e.target.value})} checked={selected("By-Nc-Sa")}></input>Atribución - No Comercial - Compartir Igual (by-nc-sa):</h1>
                                <p>No se permite un uso comercial de LA TESIS/TESINA original ni de las posibles obras derivadas, la distribución de las cuales se debe hacer con una licencia igual a la que regula LA OBRA original.</p>
                                <img src={cuatro} />
                            </Licencia>
                            <Licencia>
                                <h1><input type="radio" name="licencia_cc" value="By-Nd" onChange={(e) => setData({...data, licencia: e.target.value})} checked={selected("By-Nd")}></input>Atribución - Sin Obra Derivada (by-nd):</h1>
                                <p>Se permite el uso comercial de LA OBRA pero no la generación de obras derivadas.</p>
                                <img src={cinco} />
                            </Licencia>
                            <Licencia>
                                <h1><input type="radio" name="licencia_cc" value="By-Nc-Nd" onChange={(e) => setData({...data, licencia: e.target.value})} checked={selected("By-Nc-Nd")}></input> Atribución - No Comercial - Sin Obra Derivada (by-nc-nd):</h1>
                                <p>No se permite un uso comercial de LA OBRA original ni la generación de obras derivadas.</p>
                                <img src={seis} />
                            </Licencia>
                        </Licencias>
                    <p>En función de las condiciones elegidas por EL AUTOR, el Repositorio Hipermedial UNR seleccionará una licencia Creative Commons compatible que se incluirá y aplicará a la obra. La UNIVERSIDAD no asumirá responsabilidad alguna por otros usos no autorizados de LA OBRA o contrarios a la legislación vigente.</p>
                    </Texto>
                    <Botones>
                        <Anterior onClick={prevStep}>Anterior</Anterior>
                        <Siguiente onClick={nextStep}>Siguiente</Siguiente>
                    </Botones>
            </Contenedor>
        </div>
    )
}

*/


export default Atribucion
