import React, {useState, createRef} from 'react';
import styled from 'styled-components';
import Delete from '../../assets/delete.svg';
import Png from '../../assets/png.svg'
import { listamaterias } from './exports';
import * as Import from './exports'
import { GlobalStyle, Container, ListaHorarios, Turnos, Bloque, Dashboard, Option, Anio, Label, Row, Inicial, Borrar, Alert, Exportar, CaptchaContainer} from './Cuadro.Styled';
import { useScreenshot, createFileName } from 'use-react-screenshot'
import Reaptcha from 'reaptcha';
import { guardarCuadro, verificarCaptcha } from '../../network/lib/cuadro';

function Cuadro() {
    const [seleccionados, setSeleccionados] = useState([]);
    const [year, setYear] = useState(0);
    const [turno, setTurno] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectTerm, setSelectTerm] = useState('');

    const [captcha, setCaptcha] = useState(false)

    const [contador, setContador] = useState('A');

    const [transferData, setTransferData] = useState({});

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [text, setText] = useState("")

    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    const download = (image, { name = "cuadro-horario", extension = "png" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };
    
    const downloadScreenshot = () => {
        takeScreenShot(ref.current).then(download);
        let dispositivo = ""
        if(window.innerWidth<768){
            dispositivo = 'Mobile'
        } else {
            dispositivo = 'Desktop'
        }
        guardarCuadro({arr: seleccionados, dispositivo: dispositivo})
        .then()
        .catch()
    }

    const closeConfirm = () => {
        setOpenConfirm(false)
    }

    const closeAlert = () => {
        setOpenAlert(false)
    }

    const closeInfo = () => {
        setOpenInfo(false)
    }


    var rows = [<Dia>.</Dia>];
    for (var i = 7; i < 24; i++) {
        rows.push(<Inside key={i}>{i}:00</Inside>);
        rows.push(<Inside key={i*100}></Inside>);
    }
    var divs = [];
    for (var j = 0; j < 34; j++) {
        divs.push(<Cont key={j}>&nbsp;</Cont>);
    }

    const [turnos, setTurnos] = useState([
        {
            id: 1,
            turno: 'Mañana',
            value: false
        },
        {
            id: 2,
            turno: 'Tarde',
            value: false
        },
        {
            id: 3,
            turno: 'Noche',
            value: false
        }
    ]);

    const [checked, setChecked] = useState([
        {
            id: 1,
            value: false
        },
        {
            id: 2,
            value: false
        },
        {
            id: 3,
            value: false
        },
        {
            id: 4,
            value: false
        },
        {
            id: 5,
            value: false
        },
        {
            id: 6,
            value: false
        },
    ]);

    const verifyCaptcha = (token) =>{
        verificarCaptcha(token)
        .then((res)=>{
            if(res.status===200){
                downloadScreenshot()
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const validar = (e) => {
        let val = true;
        let superpuesta = false;
        seleccionados.forEach(item => {
            if (item.id === e.id){
                val = false;
            } else if (item.nombre === e.nombre){
                val = false;
            } else if ((((e.dia.inicio > item.dia.inicio) && (e.dia.inicio < item.dia.fin)) || ((e.dia.inicio < item.dia.inicio) && (e.dia.fin > item.dia.fin)) || ((e.dia.inicio < item.dia.inicio) && (item.dia.inicio < e.dia.fin)) || ((e.dia.inicio >= item.dia.inicio) && (e.dia.inicio < item.dia.fin))) && (e.dia.nombre === item.dia.nombre)){
                setOpenAlert(true)
                setTransferData(e)
                setText("Atención: la materia " + e.nombre + " se superpone con " + item.nombre + " el día " + item.dia.nombre + ". ¿Desea agregarla de todas formas?")
                if (openAlert) {
                    superpuesta = true;
                } else {
                    val = false;
                }
            } else if ((((e.diaDos.inicio > item.diaDos.inicio) && (e.diaDos.inicio < item.diaDos.fin)) || ((e.diaDos.inicio < item.diaDos.inicio) && (e.diaDos.fin > item.diaDos.fin)) || ((e.diaDos.inicio < item.diaDos.inicio) && (item.diaDos.inicio < e.diaDos.fin)) || ((e.diaDos.inicio >= item.diaDos.inicio) && (e.diaDos.inicio < item.diaDos.fin))) && (e.diaDos.nombre === item.diaDos.nombre)){
                setOpenAlert(true)
                setTransferData(e)
                setText("Atención: la materia " + e.nombre + " se superpone con " + item.nombre + " el día " + item.diaDos.nombre + ". ¿Desea agregarla de todas formas?");
                if (openAlert) {
                    superpuesta = true;
                } else {
                    val = false;
                }
            } else if ((((e.dia.inicio > item.diaDos.inicio) && (e.dia.inicio < item.diaDos.fin)) || ((e.dia.inicio < item.diaDos.inicio) && (e.dia.fin > item.diaDos.fin)) || ((e.dia.inicio < item.diaDos.inicio) && (item.diaDos.inicio < e.dia.fin)) || ((e.dia.inicio >= item.diaDos.inicio) && (e.dia.inicio < item.diaDos.fin))) && (e.dia.nombre === item.diaDos.nombre)){
                setOpenAlert(true)
                setTransferData(e)
                setText("Atención: la materia " + e.nombre + " se superpone con " + item.nombre + " el día " + item.diaDos.nombre + ". ¿Desea agregarla de todas formas?");
                if (openAlert) {
                    superpuesta = true;
                } else {
                    val = false;
                }
            } else if ((((e.diaDos.inicio > item.dia.inicio) && (e.diaDos.inicio < item.dia.fin)) || ((e.diaDos.inicio < item.dia.inicio) && (e.diaDos.fin > item.dia.fin)) || ((e.diaDos.inicio < item.dia.inicio) && (item.dia.inicio < e.diaDos.fin)) || ((e.diaDos.inicio >= item.dia.inicio) && (e.diaDos.inicio < item.dia.fin))) && (e.diaDos.nombre === item.dia.nombre)){
                setOpenAlert(true)
                setTransferData(e)
                setText("Atención: la materia " + e.nombre + " se superpone con " + item.nombre + " el día " + item.dia.nombre + ". ¿Desea agregarla de todas formas?");
                if (openAlert) {
                    superpuesta = true;
                } else {
                    val = false;
                }
            }
        });
        if (val){
            add(e, superpuesta)
            if (contador==='A'){
                setContador('B')
            }
            if((e.nombre==='Matemática I' || e.nombre==='Matemática I - Teórico') && contador==='A'){
                setOpenInfo(true)
            }
        }
    }


    const add = (e, superpuesta) =>{         // verificar que no coincida un dia o ambos y aclarar CUÁNDO se superpone
        setSeleccionados([
            ...seleccionados,
            {
                id: e.id, 
                nombre: e.nombre, 
                anio: e.anio,
                catedra: {
                    nombre: e.catedra.nombre,
                    docente: e.catedra.docente
                },
                dia: {
                    nombre: e.dia.nombre,
                    horaInicio: e.dia.horaInicio,
                    horaFin: e.dia.horaFin,
                    inicio: e.dia.inicio,
                    fin: e.dia.fin
                },
                diaDos: {
                    nombre: e.diaDos.nombre,
                    horaInicio: e.diaDos.horaInicio,
                    horaFin: e.diaDos.horaFin,
                    inicio: e.diaDos.inicio,
                    fin: e.diaDos.fin
                },
                turno: e.turno,
                seleccionada: true,
                superpuesta: superpuesta
            }
            ])
            listamaterias.map((i)=> {
                if(i.id === e.id){
                    i.seleccionada = true;
                    console.log(i)
                }
                if((i.nombre === e.nombre) && (i.id !== e.id)){
                    i.show = false;
                    console.log(i)
                }
                return true;
            }
        )
    }
    
    const toggleYear = (c) => {  //le paso el que seleccione
        setChecked((vals) =>  //en el arreglo asigno, al que se corresponde con el id, el valor opuesto al que tenia.
        vals.map((v) => {
            if (v.id === c.id) {
                if (!v.value === true){
                    setYear(v.id)
                } else {
                    setYear(0)
                }
                return {...v, value: !v.value}
            } else {
                if ((v.id !== c.id) && v.value) {
                    return {...v, value: false}
                } else {
                    return v;
                }
            }
        }))
    };

    const toggle = (t) => {  //le paso el que seleccione
        setTurnos((vals) =>  //en el arreglo asigno, al que se corresponde con el id, el valor opuesto al que tenia.
        vals.map((v) => {
            if (v.turno === t.turno) {
                if (!v.value === true){
                    setTurno(v.turno)
                } else {
                    setTurno('')
                }
                return {...v, value: !v.value}
            } else {
                if ((v.turno !== t.turno) && v.value) {
                    return {...v, value: false}
                } else {
                    return v;
                }
            }
        }))
    };

    const remove = (e) => {
        setSeleccionados(
            seleccionados.filter((val) => {
                listamaterias.map((i)=> {
                    if(i.id === e.id){
                        i.seleccionada = false;
                    }
                    if((i.nombre === e.nombre) && (i.id !== e.id)){
                        i.show = true;
                    }
                })
                return val.id !== e.id;
            }))
                setCaptcha(null);
    }

    const removeAll = () =>{
        setSeleccionados([]);
        listamaterias.map((i)=> {
                i.seleccionada = false;
                i.show = true;
        })
        setOpenConfirm(false);
        setContador('A')
        setChecked((vals) =>  //en el arreglo asigno, al que se corresponde con el id, el valor opuesto al que tenia.
            vals.map((v) => {
                return {...v, value: false}
            }))
        setTurnos((vals) =>  //en el arreglo asigno, al que se corresponde con el id, el valor opuesto al que tenia.
            vals.map((v) => {
                return {...v, value: false}
            }))
        setYear(0)
        setTurno('')
        setSelectTerm('')
    }


    return (
        <Container>
            <GlobalStyle/>
            {openConfirm ? <Import.ModalConfirmacion action={removeAll} close={closeConfirm}/> : null}
            {openAlert ? <Import.ModalAgregar action={add} data={transferData} close={closeAlert} text={text}/> : null}
            {openInfo ? <Import.ModalInformacion close={closeInfo}/> : null}
                <Grilla ref={ref}>
                    <ColumnaUno>
                        {rows}
                    </ColumnaUno>
                    <ColumnaDos>
                        <Dia>Lunes</Dia>
                        {divs}
                    </ColumnaDos>
                    <ColumnaTres>
                        <Dia>Martes</Dia>
                        {divs}
                    </ColumnaTres>
                    <ColumnaCuatro>
                        <Dia>Miércoles</Dia>     
                        {divs}
                    </ColumnaCuatro>
                    <ColumnaCinco>
                        <Dia>Jueves</Dia>
                        {divs}
                    </ColumnaCinco>
                    <ColumnaSeis>
                        <Dia>Viernes</Dia>
                        {divs}
                    </ColumnaSeis>
                    
                {seleccionados.map((s) => {
                    if(s.nombre==='Matemática I - Teórico'){
                        return(
                            <Import.BurbujaMatematica e={s} remove={remove}/>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                <Import.Burbuja e={s} remove={remove}></Import.Burbuja>
                                <Import.BurbujaDos e={s} remove={remove}></Import.BurbujaDos>
                            </React.Fragment>
                        );
                    }
                })}
                </Grilla>
            <Dashboard>
                <Turnos>
                    <Label>Turno</Label>
                    <Row>
                        {turnos.map((t)=>{
                                return(
                                    <label>
                                        <Option check={t.value}>
                                            <input type="checkbox" name="year" value={t.id} onChange={()=> toggle(t)}/>
                                            {t.turno}
                                        </Option>
                                    </label>
                                )
                        })}
                    </Row>
                </Turnos>
                <Turnos>
                    <Label>Año</Label>
                    <Row>
                        {checked.map((c)=>{
                                return(
                                    <label>
                                        <Anio check={c.value}>
                                            <input type="checkbox" name="year" value={c.id} onChange={()=> toggleYear(c)}/>
                                            {c.id}º Año
                                        </Anio>
                                    </label>
                                )
                        })}
                    </Row>
                </Turnos>
                <Turnos>
                    <Import.SelectSubject change={setSelectTerm} year={year} turno={turno} />
                </Turnos>
                {seleccionados.map((s)=>{
                    if (s.nombre==='Epistemología I'){
                        return <Alert><h5>Epistemología se cursa en el 2do cuatrimestre.</h5></Alert>
                    }
                })}
                <ListaHorarios>
                    <Import.SearchBox change={setSearchTerm}/>
                    <Bloque>
                        {(year===0 && turno==='' && searchTerm==='') && <Inicial>Seleccione un filtro o busque una materia para comenzar.</Inicial>}
                        {listamaterias.map((e)=> {
                            if (selectTerm==='') {
                                if (year!==0 && e.anio===year && e.show && turno===''){
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}                          
                                        </React.Fragment>
                                    )
                                } else if (turno!=='' && e.turno===turno && e.show && year===0){
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )
                                } else if (year!==0 && turno!=='' && e.anio===year && e.turno===turno && e.show) {
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )
                                }
                            }
                            if (searchTerm==='') {
                                if (year!==0 && e.anio===year && e.show && turno==='' && e.nombre===selectTerm){
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )
                                } else if (turno!=='' && e.turno===turno && e.show && year===0 && e.nombre===selectTerm){
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )
                                } else if (year!==0 && turno!=='' && e.anio===year && e.turno===turno && e.show && e.nombre===selectTerm) {
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )
                                }
                            } else { 
                                if ((e.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || e.catedra.nombre.toLowerCase().includes(searchTerm.toLowerCase())) && turno==='' && year===0 && e.show) {
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )
                                } else if ((e.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || e.catedra.nombre.toLowerCase().includes(searchTerm.toLowerCase())) && e.turno===turno && turno!=='' && e.show && year===0) {
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )
                                } else if ((e.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || e.catedra.nombre.toLowerCase().includes(searchTerm.toLowerCase())) && e.anio===year && year!==0 && e.show && turno==='') {
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )     
                                } else if ((e.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || e.catedra.nombre.toLowerCase().includes(searchTerm.toLowerCase())) && e.anio===year && e.turno===turno && e.show){
                                    return (
                                        <React.Fragment>
                                            {e.diaDos.nombre==='' ? <Import.Horario className="hidden" add={validar} remove={remove} data={e}/> : <Import.HorarioCompuesto add={validar} remove={remove} data={e}/>}     
                                        </React.Fragment>
                                    )      
                                }
                            }
                            return true;
                        }   
                        )}
                    </Bloque>
                </ListaHorarios>
                <Botones>
                    {
                        seleccionados.length > 0 ?
                        <>
                            <Borrar onClick={()=> setOpenConfirm(true)}><img src={Delete}/>Borrar todo</Borrar>
                            <Exportar onClick={()=> setCaptcha(true)}><img src={Png}/>Exportar</Exportar>
                        </>
                        : null
                    }
                </Botones>
                <CaptchaContainer>
                    {captcha? 
                        <Reaptcha sitekey="6LeWfw4fAAAAALF5zmJLuxpIcTI03UGan7HD6TDS" 
                            onVerify={responseCaptcha => verifyCaptcha(responseCaptcha)}
                        /> 
                    : null}
                </CaptchaContainer>
                </Dashboard>
        </Container>
    )
}

const Botones = styled.div`
    display: flex;
`

const Grilla = styled.div`
    margin-left: auto;
    width: fit-content;
    display: grid;
    grid-template-columns: 70px repeat(5, 8vw);
    grid-template-rows: 40px repeat(36, 25px);
    @media (max-width: 1700px){
        grid-template-columns: 70px repeat(5, 10vw);
    }
    @media (max-width: 1400px){
        grid-template-columns: 70px repeat(5, 12vw);
    }
    @media (max-width: 1270px){
        grid-template-columns: 40px repeat(5, 10vw);
    }
    @media (max-width: 1200px){
        grid-template-columns: 40px repeat(5, 11vw);
    }
    @media (max-width: 1100px){
        grid-template-columns: 40px repeat(5, 18vw);
        margin-right: auto;
        margin-left: auto;
    }
    @media (max-width: 768px){
        grid-template-columns: 30px repeat(5, 18vw);
        grid-template-rows: 40px repeat(36, 20px);
        margin-right: auto;
        margin-left: auto;
    }
`

const Dia = styled.div`
    text-align: center;
    background-color: #6667FE;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 4px solid #6667fe;
    @media (max-width: 950px){
        font-size: 16px;
    }
    @media (max-width: 850px){
        font-size: 14px;
    }
    @media (max-width: 450px){
        font-size: 12px;
    }
`

const ColumnaUno = styled.div`
    color: #6667FE;
    font-weight: 600;
    grid-column: 1/2;
    grid-row-start: 2;
    font-size: 14px;
    @media (max-width: 950px){
        font-size: 12px;
    }
    @media (max-width: 850px){
        font-size: 10px;
    }
    @media (max-width: 450px){
        font-size: 8px;
    }
    ${Dia} {
        opacity: 0;
    }
`

const ColumnaDos = styled.div`
    grid-column: 2/3;
    grid-row-start: 2;
`

const ColumnaTres = styled.div`
    grid-column: 3/4;
    grid-row-start: 2;
`

const ColumnaCuatro = styled.div`
    grid-column: 4/5;
    grid-row-start: 2;
`

const ColumnaCinco = styled.div`
    grid-column: 5/6;
    grid-row-start: 2;
`

const ColumnaSeis = styled.div`
    grid-column: 6/7;
    grid-row-start: 2;
`

const Inside = styled.div`
    outline: 0px solid #6667FE;
    height: 100%;
    text-align: right;
    padding-right: 5px;
`

const Cont = styled.div`
    box-sizing: border-box;
    border: 1px solid #6667FE;
    height: 100%;
    opacity: .2;
`

export default Cuadro;
