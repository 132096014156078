import styled from "styled-components"
import { createGlobalStyle } from 'styled-components'
import '../../assets/styles.css';
import background from '../../assets/back.svg';

export const GlobalStyleLogin = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background: url(${background}) no-repeat;
    }
`

export const ButtonIngresar = styled.button`
    width: 150px;
    padding: 10px 30px;
    background: transparent;
    border-radius: 30px;
    text-transform: uppercase;
    border: 2px solid #fff;
    font-weight: bold;
    color: #fff;
    margin-left: auto;
    float: right;
    margin-top: 30px;
    font-size: 16px;
    cursor: pointer;
`

export const Ingresar = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-right: auto;
    width: fit-content;
    //background-color: #FDFDFD;
    border-radius: 20px;
    padding: 30px 60px 50px;
    //box-shadow: 2px 4px 4px rgba(122, 122, 122, 0.12);
    label {
        margin: 1em 0 10px;
        margin-left: 0;
        color: #fff;
        font-weight: 500;
        margin-top: 100px;
    }
    input {
        width: 300px;
        padding: 20px 40px;
        border: 1px solid #f5f5f5;
        background-color: #F5F5F5;
        border-radius: 40px;
        margin-top: 10px;
        outline: none;
    }
`

export const Titulo = styled.div`
    font-weight: 600;
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    span{
        font-size: 36px;
        display: block;
        margin-bottom: 16px;
        margin-top: 20px;
    }
`