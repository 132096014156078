import React, { useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { GlobalStyle, Navbar, Cards } from './Home.Styled';
import Logo from '../../assets/fapyd.svg';
import licencia from '../../assets/licencia.svg';
import calendario from '../../assets/calendario.svg';
import certificado from '../../assets/certificado.svg';
import newsletter from '../../assets/newsletter.svg';
import Card from '../../components/Home/Card'

function Home() {

    return (
        <div>
            <GlobalStyle />
            <Navbar>
                <img src={Logo} alt=""></img>
                <Link to="/"></Link>
            </Navbar>
            <Cards>
                <Card
                    icon={licencia}
                    titulo="Generador de Licencias de Depósito"
                    link="/licencia"
                    disabled={false}
                />
                <Card
                    icon={calendario}
                    titulo="Cuadro de horario interactivo"
                    link="/cuadro"
                    disabled={false}
                />
                <Card
                    icon={certificado}
                    titulo="Gestor de solicitud de certificados"
                    link="/"
                    disabled={true}
                />
                <Card
                    icon={newsletter}
                    titulo="Gestor de InfoFAPyD"
                    link="/"
                    disabled={true}
                />
            </Cards>
        </div>
    )
}

export default Home
