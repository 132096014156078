import styled from "styled-components"
import { createGlobalStyle } from 'styled-components'
import '../../assets/styles.css';
import background from '../../assets/back.svg';

export const GlobalStyleListado = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        background: url(${background}) no-repeat;
    }
`