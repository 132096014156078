import React, {useEffect, useState} from 'react'
import { Contenedor, Texto, Siguiente, Anterior, Dato, TextArea, Input, Paso, Botones, Alerta, Small } from '../Licencia.Styled';

function Documento({setData, data, nextStep, prevStep}) {

    const [status, setStatus] = useState('');
    const [abstractlength, setAbstractlength] = useState(0);

    const verif = () => {
        if (data.titulo === '' || data.tutor === '' || data.abstract.length===''  || data.claves.uno === '' || data.claves.dos === '' || data.claves.tres === '' || data.claves.cuatro === '' || data.claves.defensa === '') {
            setStatus('Completar todos los campos.')
        } else {
            if(data.abstract.length<600){
                setStatus('El abstract debe superar los 600 caracteres.')
            }else if(data.abstract.length>1500){
                setStatus('El abstract no debe superar los 1500 caracteres.')
            }else{
                nextStep()
            }
        }
    }

const countAbstractlength = (e) =>{
    setAbstractlength(e.length);
}

    useEffect(() => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    }, [])

    return (
        <div>
            <Contenedor>
                <Paso> Paso 3 · Identificación del documento </Paso>
                <Texto>
                    <Dato>Título completo del trabajo:</Dato>
                    <Input autoFocus type="text" onChange={(e) => setData({...data, titulo: e.target.value})} value={data.titulo} required/>
                    <Dato>Nombre del tutor del proyecto:</Dato>
                    <Input type="text" onChange={(e) => setData({...data, tutor: e.target.value})} value={data.tutor} required/>
                    <Dato>Nombre del co-tutor del proyecto:</Dato>
                    <Input type="text" onChange={(e) => setData({...data, coTutor: e.target.value})} value={data.coTutor}/>
                    <Dato>Nombre del co-autor del proyecto <i>(opcional)</i>:</Dato>
                    <Input type="text" onChange={(e) => setData({...data, coAutor: e.target.value})} value={data.coAutor}/>
                    <Dato>Abstract/resumen:</Dato>
                    <i>600 - 1500 caracteres.</i>
                    <TextArea minlength="600" maxlength="1500" onChange={(e) => {setData({...data, abstract: e.target.value}); countAbstractlength(e.target.value)}} value={data.abstract} required/>
                    <Small>{abstractlength}</Small>
                    <Dato>Palabras clave para identificar al trabajo:</Dato>
                    <i>Mínimo 4.</i>
                    <Input type="text" onChange={(e) => setData({...data, claves: {...data.claves, uno: e.target.value}})} value={data.claves.uno} required/>
                    <Input type="text" onChange={(e) => setData({...data, claves: {...data.claves, dos: e.target.value}})} value={data.claves.dos} required/>
                    <Input type="text" onChange={(e) => setData({...data, claves: {...data.claves, tres: e.target.value}})} value={data.claves.tres} required/>
                    <Input type="text" onChange={(e) => setData({...data, claves: {...data.claves, cuatro: e.target.value}})} value={data.claves.cuatro} required/>
                    <Input type="text" onChange={(e) => setData({...data, claves: {...data.claves, cinco: e.target.value}})} value={data.claves.cinco}/>
                    <Input type="text" onChange={(e) => setData({...data, claves: {...data.claves, seis: e.target.value}})} value={data.claves.seis}/>
                    <Input type="text" onChange={(e) => setData({...data, claves: {...data.claves, siete: e.target.value}})} value={data.claves.siete}/>
                    <Dato>Fecha de defensa:</Dato>
                    <Input type="date" min="2014-01-01" max="2022-12-31" onChange={(e) => setData({...data, defensa: e.target.value})} value={data.defensa} required></Input>
                    <Alerta>{status}</Alerta>
                </Texto>
                <Botones>
                    <Anterior onClick={prevStep}>Anterior</Anterior>
                    <Siguiente onClick={verif}>Siguiente</Siguiente>
                </Botones>
            </Contenedor>
        </div>
    )
}

export default Documento
