import React, { useState } from 'react'
import styled from 'styled-components'
import ModalAgregarEstudiante from './Modal.AgregarEstudiante'

function AgregarEstudiante() {

  const [openAgregar, setOpenAgregar] = useState(false)

  const close = () => {
    setOpenAgregar(false)
  }

  return (
    <>
      {openAgregar ? <ModalAgregarEstudiante close={close}/>: null}
      <CTAButton onClick={() => {setOpenAgregar(true)}}>
          + Agregar estudiante
      </CTAButton>
    </>
  )
}

const CTAButton = styled.button`
    background-color: #7778fe;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    padding: 16px;
    margin-top: auto;
    cursor: pointer;
`

export default AgregarEstudiante