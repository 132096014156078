import React, { useEffect, useState } from 'react'
import { Contenedor, Texto, Siguiente, Anterior, Dato, Input, Label, Motivo, Radio, Paso, Botones, Alerta } from '../Licencia.Styled';
import ModalExcedeFileSize from '../../../components/Certificado/Modal.ExcedeFileSize'
function Publicacion({setData, data, nextStep, prevStep, status, loading, setExt}) {

    const [open, setOpen] = useState();
    const [fieldStatus, setFieldStatus] = useState('')
    const [fileOk, setFileOk] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [text, setText] = useState("");

    const verif = () => {
        // if (data.size === '') {
        //     setFieldStatus('Adjuntar archivo.')
        // } else {
        //     nextStep()
        // }
        if(fileOk){
            nextStep()
        }else{
            setOpenAlert(true)
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
        if (data.aprobacion==='Dentro de los seis meses'){
            setOpen(true);
        }
    }, [])

    const showModal = () => {
        setOpen(true)
        setData({...data, aprobacion: 'Dentro de los seis meses'})
    }

    const hideModal = () => {
        setOpen(false)
        setData({...data, aprobacion: 'Inmediata'})
    }

    function verifyFileSize(file){
        console.log(file.size)
        console.log(10e7 - 1)
        if(file.size > (10e7 - 1)){
            setFileOk(false)
            setText("El archivo subido es muy pesado")
        }else{
            if(file.size === 0){
                setText("Es necesario adjuntar un archivo de PFC")
                setFileOk(false)
            }else{
                setFileOk(true)
            }
        }
    }

    const selected = (val) => {
        if (data.aprobacion === val) {
            return true;
        } else {
            return false;
        }
    }

    const closeAlert = ()=>{
        setOpenAlert(false);
    }

    return (
        <div>
            {openAlert ? <ModalExcedeFileSize close={closeAlert}/> : null}
            <Contenedor>
                <Paso> Paso 4 · Publicación de la obra </Paso>
                <Texto>
                    <Dato>Autorizo la publicación de la obra:</Dato>
                    <Label><p><Radio type="radio" name="motivo" value="Inmediata" onChange={hideModal} checked={selected('Inmediata')}/> A partir de su aprobación/presentación.</p>
                    <p><Radio type="radio" name="motivo" value="Dentro de los seis meses" onChange={showModal} checked={selected('Dentro de los seis meses')}/> Dentro de los 6 meses posteriores a su aprobación/presentación.</p></Label>
                    {open ? 
                        <Motivo>
                            <p>Si usted se encuentra comprendido en el caso de que su producción esté protegida por derechos de Propiedad Industrial y/o acuerdos previos con terceros que impliquen la confidencialidad de los mismos, escriba a web@fapyd.unr.edu.ar.</p>
                        </Motivo>
                    : null}
                    <Dato>Adjuntar link de Drive/Dropbox/etc del PFC:</Dato>
                    <Input type="text" onChange={(e) => setData({...data, link: e.target.value})} value={data.link} placeholder="Opcional"/>
                    <Dato>Adjuntar archivo PFC en .rar/.zip/.pdf:</Dato>
                    <i>Recordá que el tamaño máximo permitido es de 100MB y la vista debe ser de una página.</i>
                    <Input type="file" name="file" onChange={(e) => {setData({...data, pfc: e.target.files[0]}); setExt(e.target.files[0].type.split("/")); verifyFileSize(e.target.files[0])}} required accept=".pdf, .rar, .zip"/>
                    <Alerta>{status}</Alerta>
                    <Alerta>{fieldStatus}</Alerta>
                </Texto>
                <Botones>
                    <Anterior onClick={prevStep}>Anterior</Anterior>
                    <Siguiente onClick={verif}>{loading ? 'Enviando...' : 'Enviar'}</Siguiente>
                </Botones>
            </Contenedor>
        </div>
    )
}

export default Publicacion
