import React, { useState, useEffect } from 'react';
import { GlobalStyleLogin, Ingresar, Titulo, ButtonIngresar, LogoFapyd, Subtitulo } from './Login.Styled';
import { useHistory } from 'react-router-dom';
import { loginAdmin, checkLoginAdmin } from '../../network/lib/admin';
import Fapyd from '../../assets/fapyd.svg';
import styled from 'styled-components';
import { Default, Ring } from 'react-awesome-spinners'

function Login() {

    let history = useHistory();

    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        username: '',
        password: '',
    })

    const ingresar =  () => {
        setLoading(true);
        loginAdmin(data).then((res)=>{
            if(!res.data.message){
                history.push('/admin/dashboard')
            }else{
                setLoading(false);
                setStatus("Usuario y/o contraseña erronea.")
            }
        })
    }

    useEffect(() => {
      checkLoginAdmin().then((res)=>{
        if (res.data.loggedIn === true) {
            history.push('/admin/dashboard')
        };
      })
    }, [])

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            ingresar();
        }
      }
    

    return (
        <div>
            <GlobalStyleLogin />
            {
                loading ?
                <>
                    <PantallaCarga>
                        <Default color="#fff" />
                    </PantallaCarga>
                </> :
                null
            }
            <Ingresar>
                <Titulo>/Admin</Titulo>
                <Subtitulo>Ingresar</Subtitulo>
                <label>Usuario</label>
                <input type="text" onKeyPress={handleKeyPress} onChange={(e) => {setData({...data, username: e.target.value})}} value={data.username}></input>
                <label>Contraseña</label>
                <input type="password" onKeyPress={handleKeyPress} onChange={(e) => {setData({...data, password: e.target.value})}} value={data.password}></input>
                {
                    status != '' ?
                    <>
                            <Message>{status}
                            <ButtonCerrar onClick={()=>setStatus("")}>X</ButtonCerrar>
                            </Message>
                        </>
                        :
                        <Espacio></Espacio>
                }
                <ButtonIngresar onClick={ingresar}>Ingresar</ButtonIngresar>
            </Ingresar>
            <LogoFapyd>
                <img src={Fapyd} />
            </LogoFapyd>
        </div>
    )
}

const Espacio= styled.div`
height:102px;
`

const PantallaCarga = styled.div`
position: absolute;
z-index: 99;
background-color: rgba(0,0,0,.5);
bottom: 0;
left: 0;
width: 100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
`

export const Message = styled.div`
@keyframes animein {
    from {opacity:0}
    to {opacity:1}
}
    display:flex;
    color: #fff;
    justify-content: space-between;
    border-radius:2em;
    align-self: center;
    padding:1.5em;
    width: 300px;
    margin-top: 2em;
    background-color: rgba(0,0,0,.3);
    animation: animein .3s;
`

const ButtonCerrar = styled.button`
self-align: right;
background-color: rgba(0,0,0,0);
border: none;
cursor:pointer;
font-weight: 800;
color: white;
`

export default Login