export const listamaterias = [
    {   id:100, 
        nombre:"Matemática I - Teórico", 
        anio:1,
        catedra: {
            nombre: "Cabrera",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "7:00",
            horaFin: "8:00",
            inicio: 4,
            fin: 6
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:101, 
        nombre:"Matemática I - Teórico", 
        anio:1,
        catedra: {
            nombre: "Cabrera",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "20:00",
            inicio: 28,
            fin: 30
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:102, 
        nombre:"Matemática I", 
        anio:1,
        catedra: {
            nombre: "Cabrera",
            docente: "Bologna"
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "10:30",
            inicio: 7,
            fin: 11
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:103, 
        nombre:"Matemática I", 
        anio:1,
        catedra: {
            nombre: "Cabrera",
            docente: "Tabakovic"
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "12:30",
            horaFin: "14:30",
            inicio: 15,
            fin: 19
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:104, 
        nombre:"Matemática I", 
        anio:1,
        catedra: {
            nombre: "Cabrera",
            docente: "Vaccaro"
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:00",
            horaFin: "10:00",
            inicio: 6,
            fin: 10
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:105, 
        nombre:"Matemática I", 
        anio:1,
        catedra: {
            nombre: "Cabrera",
            docente: "Tasada"
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "10:00",
            inicio: 6,
            fin: 10
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:106, 
        nombre:"Matemática I", 
        anio:1,
        catedra: {
            nombre: "Cabrera",
            docente: "Gauchat"
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "11:00",
            horaFin: "13:00",
            inicio: 12,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    
    {   id:110, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "Jacinto",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:111, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "García",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:112, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "Stoddart",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:113, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "Rainero",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:114, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "Carabajal",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:115, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "Valderrama",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:116, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "Rois",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:117, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "Chiarito",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:118, 
        nombre:"Intr. a la Arquitectura", 
        anio:1,
        catedra: {
            nombre: "Valderrama",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:130, 
        nombre:"Física", 
        anio:1,
        catedra: {
            nombre: "Pasch",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:131, 
        nombre:"Física", 
        anio:1,
        catedra: {
            nombre: "Pasch",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "16:00",
            horaFin: "19:00",
            inicio: 22,
            fin: 28
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:132, 
        nombre:"Física", 
        anio:1,
        catedra: {
            nombre: "Pasch",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:140, 
        nombre:"Expresión Gráfica I", 
        anio:1,
        catedra: {
            nombre: "Gamboa",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "11:30",
            horaFin: "14:30",
            inicio: 13,
            fin: 19
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:141, 
        nombre:"Expresión Gráfica I", 
        anio:1,
        catedra: {
            nombre: "Lleonart",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "13:00",
            horaFin: "16:00",
            inicio: 16,
            fin: 22
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:142, 
        nombre:"Expresión Gráfica I", 
        anio:1,
        catedra: {
            nombre: "Pistone",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:30",
            horaFin: "22:00",
            inicio: 29,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:150, 
        nombre:"Epistemología I",
        anio:1,
        catedra: {
            nombre: "Gamboa",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "10:30",
            horaFin: "13:00",
            inicio: 11,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:151, 
        nombre:"Epistemología I", 
        anio:1,
        catedra: {
            nombre: "Gamboa",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "14:00",
            horaFin: "16:30",
            inicio: 18,
            fin: 23
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:152, 
        nombre:"Epistemología I", 
        anio:1,
        catedra: {
            nombre: "Gamboa",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "21:30",
            inicio: 28,
            fin: 33
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:160, 
        nombre:"Materialidad I", 
        anio:1,
        catedra: {
            nombre: "Martín",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:161, 
        nombre:"Materialidad I", 
        anio:1,
        catedra: {
            nombre: "Perone",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "13:30",
            horaFin: "18:30",
            inicio: 17,
            fin: 27
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:162, 
        nombre:"Materialidad I", 
        anio:1,
        catedra: {
            nombre: "Panvini",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "13:30",
            horaFin: "18:30",
            inicio: 17,
            fin: 27
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:163, 
        nombre:"Materialidad I", 
        anio:1,
        catedra: {
            nombre: "Perone",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "00:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:210, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "Jacinto",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:211, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "García",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:212, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "Stoddart",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:213, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "Rainero",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:214, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "Carabajal",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:215, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "Valderrama",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:216, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "Rois",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:217, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "Chiarito",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:218, 
        nombre:"Análisis Proyectual I", 
        anio:2,
        catedra: {
            nombre: "Valderrama",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:220, 
        nombre:"Estática y resistencia", 
        anio:2,
        catedra: {
            nombre: "Geremía",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "10:00",
            horaFin: "13:00",
            inicio: 10,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:222, 
        nombre:"Estática y resistencia", 
        anio:2,
        catedra: {
            nombre: "Geremía",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "13:30",
            horaFin: "16:30",
            inicio: 17,
            fin: 23
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:223, 
        nombre:"Estática y resistencia", 
        anio:2,
        catedra: {
            nombre: "Bogado",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "13:30",
            horaFin: "16:30",
            inicio: 17,
            fin: 23
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:224, 
        nombre:"Estática y resistencia", 
        anio:2,
        catedra: {
            nombre: "Bogado",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:230, 
        nombre:"Expresión Gráfica II", 
        anio:2,
        catedra: {
            nombre: "Gamboa",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "11:30",
            horaFin: "14:30",
            inicio: 13,
            fin: 19
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:231, 
        nombre:"Expresión Gráfica II", 
        anio:2,
        catedra: {
            nombre: "Lleonart",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "16:00",
            horaFin: "19:00",
            inicio: 22,
            fin: 28
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:232, 
        nombre:"Expresión Gráfica II", 
        anio:2,
        catedra: {
            nombre: "Pistone",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:30",
            horaFin: "22:00",
            inicio: 29,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:240, 
        nombre:"Geometría Descriptiva", 
        anio:2,
        catedra: {
            nombre: "Rainero",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "9:00",
            horaFin: "12:00",
            inicio: 8,
            fin: 14
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:241, 
        nombre:"Geometría Descriptiva", 
        anio:2,
        catedra: {
            nombre: "Rainero",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:00",
            horaFin: "16:00",
            inicio: 16,
            fin: 22
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:242, 
        nombre:"Geometría Descriptiva", 
        anio:2,
        catedra: {
            nombre: "Rainero",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "15:30",
            horaFin: "18:30",
            inicio: 21,
            fin: 27
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:243, 
        nombre:"Geometría Descriptiva", 
        anio:2,
        catedra: {
            nombre: "Rainero",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "16:00",
            horaFin: "19:00",
            inicio: 22,
            fin: 28
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:244, 
        nombre:"Geometría Descriptiva", 
        anio:2,
        catedra: {
            nombre: "Rainero",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:250, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Albertalli",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:251, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Benedetti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:252, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Brarda",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:253, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Cicutti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:254, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Dócola",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "10:00",
            horaFin: "13:00",
            inicio: 10,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:255, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Brarda",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "13:00",
            horaFin: "16:00",
            inicio: 16,
            fin: 22
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:256, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Dócola",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "14:00",
            horaFin: "17:00",
            inicio: 18,
            fin: 24
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:257, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Albertalli",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:258, 
        nombre:"Historia de la Arq. I", 
        anio:2,
        catedra: {
            nombre: "Cicutti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:260, 
        nombre:"Materialidad II", 
        anio:2,
        catedra: {
            nombre: "Perone",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:261, 
        nombre:"Materialidad II", 
        anio:2,
        catedra: {
            nombre: "Martín",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:262, 
        nombre:"Materialidad II", 
        anio:2,
        catedra: {
            nombre: "Panvini",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "13:30",
            horaFin: "18:30",
            inicio: 17,
            fin: 27
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:263, 
        nombre:"Materialidad II", 
        anio:2,
        catedra: {
            nombre: "Perone",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "00:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "00:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:264, 
        nombre:"Materialidad II", 
        anio:2,
        catedra: {
            nombre: "Martín",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "00:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "00:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:301, 
        nombre:"Matemática II", 
        anio:3,
        catedra: {
            nombre: "Cabrera",
            docente: "Bologna REC"
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "10:30",
            horaFin: "12:30",
            inicio: 11,
            fin: 15
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:302, 
        nombre:"Matemática II", 
        anio:3,
        catedra: {
            nombre: "Cabrera",
            docente: "Gauchat REC"
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "13:00",
            horaFin: "15:00",
            inicio: 16,
            fin: 20
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:310, 
        nombre:"Análisis Proyectual II", 
        anio:3,
        catedra: {
            nombre: "Jacinto",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:311, 
        nombre:"Análisis Proyectual II", 
        anio:3,
        catedra: {
            nombre: "García",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:312, 
        nombre:"Análisis Proyectual II", 
        anio:3,
        catedra: {
            nombre: "Stoddart",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:313, 
        nombre:"Análisis Proyectual II", 
        anio:3,
        catedra: {
            nombre: "Rainero",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:314, 
        nombre:"Análisis Proyectual II", 
        anio:3,
        catedra: {
            nombre: "Carabajal",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:315, 
        nombre:"Análisis Proyectual II", 
        anio:3,
        catedra: {
            nombre: "Rois",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:00",
            inicio: 17,
            fin: 26
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:316, 
        nombre:"Análisis Proyectual II", 
        anio:3,
        catedra: {
            nombre: "Chiarito",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:317, 
        nombre:"Análisis Proyectual II", 
        anio:3,
        catedra: {
            nombre: "Valderrama",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        diaDos: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 28,
            fin: 37
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:320, 
        nombre:"Intr. al Urbanismo", 
        anio:3,
        catedra: {
            nombre: "Kawano",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "9:00",
            horaFin: "12:00",
            inicio: 8,
            fin: 14
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:321, 
        nombre:"Intr. al Urbanismo", 
        anio:3,
        catedra: {
            nombre: "Kingsland",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:322, 
        nombre:"Intr. al Urbanismo", 
        anio:3,
        catedra: {
            nombre: "Bragos",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:330, 
        nombre:"Historia de la Arq. II", 
        anio:3,
        catedra: {
            nombre: "Albertalli",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:331, 
        nombre:"Historia de la Arq. II", 
        anio:3,
        catedra: {
            nombre: "Benedetti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:332, 
        nombre:"Historia de la Arq. II", 
        anio:3,
        catedra: {
            nombre: "Brarda",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:333, 
        nombre:"Historia de la Arq. II", 
        anio:3,
        catedra: {
            nombre: "Cicutti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:334, 
        nombre:"Historia de la Arq. II", 
        anio:3,
        catedra: {
            nombre: "Dócola",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "14:00",
            horaFin: "17:00",
            inicio: 18,
            fin: 24
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:335, 
        nombre:"Historia de la Arq. II", 
        anio:3,
        catedra: {
            nombre: "Brarda",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "13:00",
            horaFin: "16:00",
            inicio: 16,
            fin: 22
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:336, 
        nombre:"Historia de la Arq. II", 
        anio:3,
        catedra: {
            nombre: "Albertalli",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:337, 
        nombre:"Historia de la Arq. II",
        anio:3,
        catedra: {
            nombre: "Cicutti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:340, 
        nombre:"Materialidad III", 
        anio:3,
        catedra: {
            nombre: "Martín",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:341, 
        nombre:"Materialidad III", 
        anio:3,
        catedra: {
            nombre: "Perone",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:342, 
        nombre:"Materialidad III", 
        anio:3,
        catedra: {
            nombre: "Panvini",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "14:30",
            horaFin: "17:30",
            inicio: 19,
            fin: 25
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:343, 
        nombre:"Materialidad III", 
        anio:3,
        catedra: {
            nombre: "Panvini",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:344, 
        nombre:"Materialidad III", 
        anio:3,
        catedra: {
            nombre: "Martín",
            docente: ""
        },
        dia: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:350, 
        nombre:"Diseño de Estructuras I", 
        anio:3,
        catedra: {
            nombre: "Geremía",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:351, 
        nombre:"Diseño de Estructuras I", 
        anio:3,
        catedra: {
            nombre: "Bogado",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:352, 
        nombre:"Diseño de Estructuras I", 
        anio:3,
        catedra: {
            nombre: "Bogado",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:353, 
        nombre:"Diseño de Estructuras I", 
        anio:3,
        catedra: {
            nombre: "Geremía",
            docente: ""
        },
        dia: {
            nombre: "Jueves",
            horaInicio: "13:30",
            horaFin: "18:30",
            inicio: 17,
            fin: 27
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:420, 
        nombre:"Proyecto Arquitectónico I", 
        anio:4,
        catedra: {
            nombre: "Beltramone",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:421, 
        nombre:"Proyecto Arquitectónico I", 
        anio:4,
        catedra: {
            nombre: "Paganini",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:422, 
        nombre:"Proyecto Arquitectónico I", 
        anio:4,
        catedra: {
            nombre: "Carabajal",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:423, 
        nombre:"Proyecto Arquitectónico I", 
        anio:4,
        catedra: {
            nombre: "Barrale",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "15:00",
            horaFin: "20:00",
            inicio: 20,
            fin: 30
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "15:00",
            horaFin: "20:00",
            inicio: 20,
            fin: 30
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:424, 
        nombre:"Proyecto Arquitectónico I", 
        anio:4,
        catedra: {
            nombre: "Barrale",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:425, 
        nombre:"Proyecto Arquitectónico I", 
        anio:4,
        catedra: {
            nombre: "Ciccero",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:410, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Albertalli",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:411, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Brarda",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:412, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Cicutti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:413, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Dócola",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "10:00",
            horaFin: "13:00",
            inicio: 10,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:414, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Benedetti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "13:30",
            horaFin: "16:30",
            inicio: 17,
            fin: 23
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:415, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Dócola",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "14:00",
            horaFin: "17:00",
            inicio: 18,
            fin: 24
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:416, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Albertalli",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:417, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Cicutti",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:418, 
        nombre:"Historia de la Arq. III",
        anio:4,
        catedra: {
            nombre: "Benedetti",
            docente: "Cursado virtual"
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:430, 
        nombre:"Producción Edilicia I", 
        anio:4,
        catedra: {
            nombre: "Solari",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:431, 
        nombre:"Producción Edilicia I", 
        anio:4,
        catedra: {
            nombre: "Panvini",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:432, 
        nombre:"Producción Edilicia I", 
        anio:4,
        catedra: {
            nombre: "Solari",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:433, 
        nombre:"Producción Edilicia I", 
        anio:4,
        catedra: {
            nombre: "Panvini",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:450, 
        nombre:"Diseño de Estructuras II", 
        anio:4,
        catedra: {
            nombre: "Bogado",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:451, 
        nombre:"Diseño de Estructuras II", 
        anio:4,
        catedra: {
            nombre: "Geremía",
            docente: ""
        },
        dia: {
            nombre: "Jueves",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 6,
            fin: 12
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:460, 
        nombre:"Análisis Urbanístico", 
        anio:4,
        catedra: {
            nombre: "Kawano",
            docente: ""
        },
        dia: {
            nombre: "Jueves",
            horaInicio: "9:00",
            horaFin: "12:00",
            inicio: 8,
            fin: 14
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:461, 
        nombre:"Análisis Urbanístico", 
        anio:4,
        catedra: {
            nombre: "Kingsland",
            docente: ""
        },
        dia: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:462, 
        nombre:"Análisis Urbanístico", 
        anio:4,
        catedra: {
            nombre: "Bragos",
            docente: ""
        },
        dia: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:510, 
        nombre:"Proyecto Arquitectónico II", 
        anio:5,
        catedra: {
            nombre: "Beltramone",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:511, 
        nombre:"Proyecto Arquitectónico II", 
        anio:5,
        catedra: {
            nombre: "Paganini",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:512, 
        nombre:"Proyecto Arquitectónico II", 
        anio:5,
        catedra: {
            nombre: "Carabajal",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:513, 
        nombre:"Proyecto Arquitectónico II", 
        anio:5,
        catedra: {
            nombre: "Barrale",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "15:00",
            horaFin: "20:00",
            inicio: 20,
            fin: 30
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "15:00",
            horaFin: "20:00",
            inicio: 20,
            fin: 30
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:514, 
        nombre:"Proyecto Arquitectónico II", 
        anio:5,
        catedra: {
            nombre: "Barrale",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:515, 
        nombre:"Proyecto Arquitectónico II", 
        anio:5,
        catedra: {
            nombre: "Ciccero",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:520, 
        nombre:"Producción Edilicia II", 
        anio:5,
        catedra: {
            nombre: "Solari",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:521, 
        nombre:"Producción Edilicia II", 
        anio:5,
        catedra: {
            nombre: "Panvini",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "8:00",
            horaFin: "13:00",
            inicio: 6,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:522, 
        nombre:"Producción Edilicia II", 
        anio:5,
        catedra: {
            nombre: "Solari",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:523, 
        nombre:"Producción Edilicia II", 
        anio:5,
        catedra: {
            nombre: "Panvini",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:530, 
        nombre:"Epistemología II", 
        anio:5,
        catedra: {
            nombre: "Gamboa",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "10:30",
            horaFin: "13:00",
            inicio: 11,
            fin: 16
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:531, 
        nombre:"Epistemología II", 
        anio:5,
        catedra: {
            nombre: "Gamboa",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "14:00",
            horaFin: "16:30",
            inicio: 18,
            fin: 23
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:532, 
        nombre:"Epistemología II", 
        anio:5,
        catedra: {
            nombre: "Gamboa",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "21:30",
            inicio: 28,
            fin: 33
        },
        diaDos: {
            nombre: "",
            horaInicio: "8:00",
            horaFin: "11:00",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:540, 
        nombre:"Intervención Urbanística", 
        anio:5,
        catedra: {
            nombre: "Kawano",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "9:00",
            horaFin: "12:00",
            inicio: 8,
            fin: 14
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:541, 
        nombre:"Intervención Urbanística", 
        anio:5,
        catedra: {
            nombre: "Bragos",
            docente: ""
        },
        dia: {
            nombre: "Miércoles",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:542, 
        nombre:"Intervención Urbanística", 
        anio:5,
        catedra: {
            nombre: "Kingsland",
            docente: ""
        },
        dia: {
            nombre: "Jueves",
            horaInicio: "19:00",
            horaFin: "22:00",
            inicio: 28,
            fin: 34
        },
        diaDos: {
            nombre: "",
            horaInicio: "19:00",
            horaFin: "23:30",
            inicio: 0,
            fin: 0
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:610, 
        nombre:"Proyecto Final de Carrera", 
        anio:6,
        catedra: {
            nombre: "Beltramone",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:611, 
        nombre:"Proyecto Final de Carrera", 
        anio:6,
        catedra: {
            nombre: "Paganini",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:612, 
        nombre:"Proyecto Final de Carrera", 
        anio:6,
        catedra: {
            nombre: "Carabajal",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "8:30",
            horaFin: "13:00",
            inicio: 7,
            fin: 16
        },
        turno: "Mañana",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:613, 
        nombre:"Proyecto Final de Carrera", 
        anio:6,
        catedra: {
            nombre: "Barrale",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "15:00",
            horaFin: "20:00",
            inicio: 20,
            fin: 30
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "15:00",
            horaFin: "20:00",
            inicio: 20,
            fin: 30
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:614, 
        nombre:"Proyecto Final de Carrera", 
        anio:6,
        catedra: {
            nombre: "Barrale",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:615, 
        nombre:"Proyecto Final de Carrera", 
        anio:6,
        catedra: {
            nombre: "Ciccero",
            docente: ""
        },
        dia: {
            nombre: "Martes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        diaDos: {
            nombre: "Viernes",
            horaInicio: "19:00",
            horaFin: "0:00",
            inicio: 28,
            fin: 38
        },
        turno: "Noche",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:620, 
        nombre:"Inglés I", 
        anio:6,
        catedra: {
            nombre: "Allen",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "15:00",
            horaFin: "18:00",
            inicio: 20,
            fin: 26
        },
        diaDos: {
            nombre: "",
            horaInicio: "15:00",
            horaFin: "20:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
    {   id:621, 
        nombre:"Inglés II", 
        anio:6,
        catedra: {
            nombre: "Allen",
            docente: ""
        },
        dia: {
            nombre: "Lunes",
            horaInicio: "16:30",
            horaFin: "19:30",
            inicio: 23,
            fin: 29
        },
        diaDos: {
            nombre: "",
            horaInicio: "15:00",
            horaFin: "20:00",
            inicio: 0,
            fin: 0
        },
        turno: "Tarde",
        superpuesta: false,
        seleccionada: false,
        show: true
    },
  ];


export const listaHoras = [
    {
        id: 1,
        hora: "7:00",
        value: 4,
    },
    {
        id: 2,
        hora: "7:30",
        value: 5,
    },
    {
        id: 3,
        hora: "8:00",
        value: 6,
    },
    {
        id: 4,
        hora: "8:30",
        value: 7,
    },
    {
        id: 5,
        hora: "9:00",
        value: 8,
    },
    {
        id: 6,
        hora: "9:30",
        value: 9,
    },
    {
        id: 7,
        hora: "10:00",
        value: 10,
    },
    {
        id: 8,
        hora: "10:30",
        value: 11,
    },
    {
        id: 9,
        hora: "11:00",
        value: 12,
    },
    {
        id: 10,
        hora: "11:30",
        value: 13,
    },
    {
        id: 11,
        hora: "12:00",
        value: 14,
    },
    {
        id: 12,
        hora: "12:30",
        value: 15,
    },
    {
        id: 13,
        hora: "13:00",
        value: 16,
    },
    {
        id: 14,
        hora: "13:30",
        value: 17,
    },
    {
        id: 15,
        hora: "14:00",
        value: 18,
    },
    {
        id: 16,
        hora: "14:30",
        value: 19,
    },
    {
        id: 17,
        hora: "15:00",
        value: 20,
    },
    {
        id: 18,
        hora: "15:30",
        value: 21,
    },
    {
        id: 19,
        hora: "16:00",
        value: 22,
    },
    {
        id: 20,
        hora: "16:30",
        value: 23,
    },
    {
        id: 21,
        hora: "17:00",
        value: 24,
    },
    {
        id: 22,
        hora: "17:30",
        value: 25,
    },
    {
        id: 23,
        hora: "18:00",
        value: 26,
    },
    {
        id: 24,
        hora: "18:30",
        value: 27,
    },
    {
        id: 25,
        hora: "19:00",
        value: 28,
    },
    {
        id: 26,
        hora: "19:30",
        value: 29,
    },
    {
        id: 27,
        hora: "20:00",
        value: 30,
    },
    {
        id: 28,
        hora: "20:30",
        value: 31,
    },
    {
        id: 29,
        hora: "21:00",
        value: 32,
    },
    {
        id: 30,
        hora: "21:30",
        value: 33,
    },
    {
        id: 31,
        hora: "22:00",
        value: 34,
    },
    {
        id: 32,
        hora: "22:30",
        value: 35,
    },
    {
        id: 33,
        hora: "23:00",
        value: 36,
    },
    {
        id: 34,
        hora: "23:30",
        value: 37,
    },
    {
        id: 35,
        hora: "0:00",
        value: 38,
    }
]