import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

export const Horario = ({add, remove, data}) => {
    
    const [val, setVal] = useState(false)
    const [color, setColor] = useState(false)

    const toggle = (data) => {  
        if (val){
            remove(data)
            setVal(false)
        } else {
            setVal(true)
            add(data)
        }
    };  

    useEffect(() => {
      if(data.nombre === 'Matemáticas I - Teórico'){
        setColor(true)
      } else {
        setColor(false)
      }
    }, [])
    

    return (
        <Contenedor onClick={() => toggle(data)} destacado={data.seleccionada} color={color}>
            <Dia destacado={data.seleccionada}>{data.dia.nombre}</Dia>
            <Texto>
                <Reg destacado={data.seleccionada}>
                    <p>{data.nombre}</p>
                    <p></p>
                </Reg>
                <Bold destacado={data.seleccionada}>
                    <p>Cátedra {data.catedra.nombre}</p>
                </Bold>
            </Texto>
            <Hora destacado={data.seleccionada}>
                {data.dia.horaInicio} - {data.dia.horaFin}
            </Hora>
            {/* <Botones>
                <button onClick={() => add(data)}>+</button>
                <button onClick={() => remove(data)}>x</button>
            </Botones> */}
        </Contenedor>
    )
}

const Dia = styled.div`
    width: max-content;
    height: max-content;
    background-color: transparent;
    color: ${props => props.destacado ? "white" : "#7778fe"};
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
    margin: 10px;
    margin-left: 20px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width:1400px){
        font-size: 10px;
        padding: 6px 14px;
    }
    @media(max-width: 768px){
        margin-left: 0;
        padding: 6px 2px;
    }
`

const Contenedor = styled.div`
    display: grid;
    grid-template-columns: 25% 55% 20%;
    border-bottom: 1px solid rgba(196, 196, 196, 0.5);
    width: 500px;
    border-radius: ${props => props.destacado ? "30px" : "0"};
    cursor: pointer;
    background-color: ${props => props.destacado ? props.color ? "#383AFF" : "#7778fe" : "transparent"};
    color: ${props => props.destacado ? "white" : "#7778fe"};
    @media (max-width: 1400px){
        width: 350px;
    }
    @media (max-width: 450px){
        width: 320px;
    }
    @media (max-width: 390px){
        width: 270px;
        grid-template-columns: 25% 45% 30%;
    }
`

const Hora = styled.div`
    width: max-content;
    height: max-content;
    background-color: transparent;
    color: ${props => props.destacado ? "white" : "#000"};
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width:1400px){
        font-size: 10px;
        padding: 6px 14px;
    }
    @media(max-width: 768px){
        padding: 0;
    }
`


const Texto = styled.div`
    width: 95%;
    padding: 10px;
    @media (max-width: 450px){
        padding: 4px;
    }
`
const Reg = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.destacado ? "white" : "#989898"};
    display: flex;
    @media (max-width:1400px){
        font-size: 10px;
    }
    p{
        margin: 0;
    }
    p:nth-child(1){
        margin-right: auto;
    }
`
const Bold = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.destacado ? "white" : "#2F2F2F"};
    display: flex;
    @media (max-width:1400px){
        font-size: 12px;
    }
    p{
        margin: 0;
    }
    p:nth-child(1){
        margin-right: auto;
    }
    @media(max-width: 450px){
        font-size: 11px;
    }
`
const Botones = styled.div`
    grid-column: 3/4;
    display: flex;
    flex-direction: row;
    margin-left: 1em;
    margin-top: auto;
    margin-bottom: auto;
    button{
        padding: 10px;
        border-radius: 50px;
        border: 0px solid;
        line-height: 10px;
        height: 30px;
        font-size: 16px;
        font-weight: bold;
        color: #7778fe;
        background-color: transparent;
        border: 1px solid #7778fe;
        cursor: pointer;
    }
    button:nth-child(2){
        margin-left: 1em;
        color: #7778fe;
        background-color: transparent;
        border: 1px solid #7778fe;
    }
    @media (max-width: 450px){
        margin-left: 0;
        font-size: 10px;
        button{
            padding: 6px;
            height: 22px;
        }
        button:nth-child(2){
            margin-left: .2em;
            color: #7778fe;
            background-color: transparent;
            border: 1px solid #7778fe;
        }
    }
`