import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ArrowLeft from '../../assets/arrow-left.svg'
import Licencia from '../../assets/lic.svg'
import Usuario from '../../assets/usuario.svg'
import Horario from '../../assets/horario.svg'
import { useHistory } from 'react-router-dom';
import { logoutAdmin, checkLoginAdmin } from '../../network/lib/admin';

function Dashboard({change}) {

    let history = useHistory();

    const [opciones, setOpciones] = useState([
        {
            id: 1,
            titulo: "Licencias",
            value: true,
            icon: Licencia
        },
        {
            id: 2,
            titulo: "Estudiantes",
            value: false,
            icon: Usuario
        },
        {
            id: 3,
            titulo: "Horarios",
            value: false,
            icon: Horario
        },
    ]);

    const toggleVisibility = (opcion) => {
        setOpciones((valores) =>  
          valores.map((valor) => (valor.id === opcion.id ? { ...valor, value: !valor.value } : (((valor.id !== opcion.id) && valor.value) ? {...valor, value: false} : valor))
        ));
    };

    const logout = () => {
        logoutAdmin().then((res)=>{
            history.push('/admin')
        })
    }

    useEffect(() => {
        checkLoginAdmin().then((res)=>{
            if (res.data.loggedIn === false) {
                history.push('/admin')
            };
        })
      }, [])

  return (
    <Container>
        <Salir onClick={logout}>
            <Icon src={ArrowLeft}/>
            <span>Salir</span>
        </Salir>
        {opciones.map((opcion)=>{
            return(
                <Opcion onClick={() => {toggleVisibility(opcion); change(opcion.id)}} visible={opcion.value}>
                    <Icon src={opcion.icon}/>
                    <span>{opcion.titulo}</span>
                </Opcion>
            )
        })}
    </Container>
  )
}

const Container = styled.div`
    width: 300px;
    padding: 50px 25px;
    background-color: #7778fe;
    display: flex;
    flex-direction: column;
`

const Salir = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    span{
        margin-top: auto;
        margin-bottom: auto;
    }
    margin-bottom: 50px;
    cursor: pointer;
`

const Icon = styled.img`
    margin-right: 10px;
    width: 30px;
    height: auto;
`

const Opcion = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    background-color: ${props => props.visible ? '#8788FF': 'none'};
    border-radius: 10px;
    padding: 10px 20px;
    width: auto;
    display: flex;
    cursor: pointer;
    span{
        margin-top: auto;
        margin-bottom: auto;
    }
`

export default Dashboard