import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

function Card({icon, titulo, link, disabled}) {
    return (
        <Container disabled={disabled}>
            <Link className="none" to={link}>
                <Imagen><img src={icon}></img></Imagen>
                <Titulo>{titulo}</Titulo>
            </Link>
        </Container>
    )
}

const Container = styled.div`
    width: 300px;
    height: 450px;
    border-radius: 20px;
    margin: 50px 50px;
    box-shadow: ${props => props.disabled ? 'none' : '2px 4px 4px rgba(122, 122, 122, 0.08)'};
    cursor: pointer;
    transition: ${props => props.disabled ? 'none' : '.2s all ease-in-out'};
    &:hover {
        transform: ${props => props.disabled ? 'none' : 'scale(1.05)'};
    }
    background-color: ${props => props.disabled ? '#EDECEC' : '#FCFCFC'};
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 100px;
    }
    .none{
        text-decoration: none;
    }
`

const Imagen = styled.div`
    height: 200px;
`

const Titulo = styled.div`
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    padding: 0 30px;
    color: #4A4A4A;
`

export default Card
