import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Fapyd from '../../assets/fapyd.svg';
import styled, { keyframes } from 'styled-components'
import { GlobalStyle, Ingresar, Titulo, ButtonIngresar, Izquierda, Derecha, Container, Message, ButtonContainer, Bottom, ContenedorIngresar } from './Licencia.Styled';
import Unr from '../../assets/unr.svg'
import { checkLoginLicencia, loginLicencia } from '../../network/lib/licencia'
import { Default, Ring } from 'react-awesome-spinners'

function Licencia() {

    const [cargando, setCargando] = useState(false);

    const [status, setStatus] = useState("");
    let history = useHistory();

    const [legajo, setLegajo] = useState({
        letra: 'X',
        numeros: '0000',
        numero: '0',
    })

    const ingresar = () => {
        setCargando(true);
        loginLicencia(legajo).then((response)=> {
            if (response.data.message){
                setStatus(response.data.message);
                setCargando(false);
            } else {
                if (response.data[0].legajo.length > 0){
                    history.push('/licencia/home')
                }
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    useEffect(() => {
        checkLoginLicencia().then((response)=>{
        if (response.data.loggedIn === true) {
            history.push('/licencia/home')
        };
        });
    }, );

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
        ingresar();
        }
    }

    return (
            <Container>
                <GlobalStyle />
                <Izquierda />
                <Derecha>
                    <Ingresar>
                        <Titulo>Generador de<span>Licencias de depósito</span></Titulo>
                        <label>Ingresá con tu Nº de legajo.</label>
                        <ContenedorIngresar>
                            <input type="text" maxLength="1" value={legajo.letra} onKeyPress={handleKeyPress} onChange={(e)=>setLegajo({...legajo, letra: e.target.value})}></input><input type="text" maxLength="1" disabled value="-" onKeyPress={handleKeyPress}></input><input type="text" maxLength="4" value={legajo.numeros} onKeyPress={handleKeyPress} onChange={(e)=>setLegajo({...legajo, numeros: e.target.value})}></input><input type="text" maxLength="1" disabled value="/"></input><input type="text" maxLength="1" value={legajo.numero} onKeyPress={handleKeyPress} onChange={(e)=>setLegajo({...legajo, numero: e.target.value})}></input>
                        </ContenedorIngresar>
                        <ButtonContainer>
                            <ButtonIngresar onClick={ingresar}>Ingresar</ButtonIngresar>
                        </ButtonContainer>
                        {
                            status != '' ?
                            <>
                                <Message>{status}
                                <ButtonCerrar onClick={()=>setStatus("")}>X</ButtonCerrar>
                                </Message>
                            </>
                            :
                            <Espacio></Espacio>
                        }
                    </Ingresar>
                    <Bottom>
                        <FapydImg>
                            <img src={Fapyd}></img>
                        </FapydImg>
                        <img src={Unr}></img>
                    </Bottom>
                </Derecha>
                {
                    cargando ?
                    <>
                        <PantallaCarga>
                            <Default color="#fff" />
                        </PantallaCarga>
                    </> :
                    null
                }
            </Container>
    )
}

const PantallaCarga = styled.div`
position: absolute;
z-index: 99;
background-color: rgba(0,0,0,.5);
bottom: 0;
left: 0;
width: 100%;
height:100%;
display:flex;
align-items:center;
justify-content:center;
`

const FapydImg = styled.div`
transform: translateY(20px)
`

const Espacio= styled.div`
height:124px;
`

const ButtonCerrar = styled.button`
self-align: center;
background-color: rgba(0,0,0,0);
border: none;
cursor:pointer;
font-weight: 800;
color: white;
`

export default Licencia
