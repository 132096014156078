import React from 'react'
import styled from 'styled-components'

function ModalExcedeFileSize({close}) {
    return (
    <Container>
        <Modal>
            <Alerta>
                <Titulo>Atención</Titulo>
            </Alerta>
            <Alerta>
                <Texto>El tamaño del archivo ingresado excede lo permitido.</Texto>
                <Texto>Adjunte un archivo mas pequeño.</Texto>
            </Alerta>
            <Botones>
                <Confirmar onClick={close}>Confirmar</Confirmar>
            </Botones>
        </Modal>
    </Container>
)}

const Container = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 200px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
`

const Modal = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    max-width: 500px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 768px){
        margin: 10px;
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
`

const Botones = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @media(max-width: 768px){
        margin-top: 20px;
    }
`

const Confirmar = styled.button`
    cursor: pointer;
    border: none;
    padding: 15px 38px;
    width: 30%;
    background-color: #7778fe;
    border-radius: 14px;
    font-weight: 600;
    color: #fff;
    @media(max-width: 768px){
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 13px;
    }
`

const Alerta = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`

const Titulo = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #525252;
    margin-bottom: 30px;
    @media(max-width: 768px){
        font-size: 18px;
    }
`

const Texto = styled.div`
    color: #8F8F8F;
    font-weight: 600;
    font-size: 14px;
    @media(max-width: 768px){
        font-size: 11px;
    }
`
export default ModalExcedeFileSize