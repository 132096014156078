import { axiosClient } from "../api";

export const loginAdmin = (data) => {
    return(axiosClient.post('/admin/login', data))
}

export const logoutAdmin = () => {
    return(axiosClient.get('/admin/logout'))
}

export const registrarAdmin = (data) => {
    return(axiosClient.post('/admin/registrar', data))
}

export const checkLoginAdmin = () => {
    return(axiosClient.get('/admin/login'))
}

export const getLicencias = () => {
    return(axiosClient.get('/admin/listado'))
}

export const generarLicencia = (data) => {
    return(axiosClient.post('/admin/pdf', data))
}

export const getEstudiantes = () => {
    return(axiosClient.get('/admin/estudiantes'))
}

export const postEstudiante = (data) => {
    return(axiosClient.post('/admin/estudiante', data))
}

export const updateEstudiante = (data) => {
    return(axiosClient.put('/admin/estudiante', data))
}

export const deleteEstudiante = (id) => {
    return(axiosClient.delete(`/admin/estudiante/${id}`))
}

export const postHorario = (data) => {
    return(axiosClient.post('/admin/horario', data))
}

export const getHorarios = () => {
    return(axiosClient.get('/admin/horarios'))
}

export const updateHorario = (data) => {
    return(axiosClient.put('/admin/horario', data))
}

export const deleteHorario = (id) => {
    return(axiosClient.delete(`/admin/horario/${id}`))
}